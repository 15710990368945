
import { useAppStore } from "@/store";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";
import { IonIcon } from '@ionic/vue';
import { pricetagsOutline, calendarClearOutline, sparklesOutline, pinOutline, documentTextOutline, navigateOutline, calendarOutline } from 'ionicons/icons';
import MarkdownWrap from "@/components/MarkdownWrap.vue"
import ReportButton from "@/components/ReportButton.vue"

export default defineComponent({
  name: "UserCountryModal",
  components: {
    MarkdownWrap,
    IonIcon,
    ReportButton
  },
  setup() {
    const { getStoredUserId, getStoredUser, getStoredUserCountries, addModalUserCountry } = useAppStore()
    const { modalCountry } = storeToRefs(useAppStore())
    const usernameProfile = ref()
    const countryTrips:any = ref([])

    if((!modalCountry.value.userCountry || modalCountry.value.userCountry == undefined) && typeof modalCountry.value.code === 'string') {
      getStoredUserId(modalCountry.value.user).then(username => {
        console.log("got id", username)
        getStoredUserCountries(username.uid)
          .then(countries => {
            console.log(modalCountry.value.code)
            console.log(countries.data.find((x) => x.country === modalCountry.value.code))
            addModalUserCountry(countries.data.find((x) => x.country === modalCountry.value.code))
          })
        getStoredUser(username.uid)
          .then(user => {
            usernameProfile.value = user
            user.statsTrips.forEach((trip) => {
              if(trip.countries && trip.countries.includes(modalCountry.value.code?.toLowerCase())) countryTrips.value.push(trip)
            })
          })
      })
    }

    

    return {
        modalCountry,
        pricetagsOutline,
        calendarClearOutline,
        sparklesOutline,
        documentTextOutline,
        pinOutline,
        navigateOutline,
        calendarOutline,
        countryTrips
    }
  }
});

function getStoredUser(uid: any) {
  throw new Error("Function not implemented.");
}
