
import { defineComponent, ref, watch } from "vue";
import { allCountries, useAppStore, useAuthStore } from "@/store";
import { alertController, IonButton, IonContent, IonIcon, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { checkmarkCircleOutline, addCircleOutline, earthOutline } from 'ionicons/icons';
import FlagIcon from "@/components/FlagIcon.vue"
import UserAvatar from "@/components/UserAvatar.vue"
import GlobalCountryModal from "@/components/Countries/GlobalCountryModal.vue"
import OwnCountryModal from "@/components/Countries/OwnCountryModal.vue"
import UserCountryModal from "@/components/Countries/UserCountryModal.vue"
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "CountryModal", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButton,
    FlagIcon,
    UserAvatar,
    GlobalCountryModal,
    OwnCountryModal,
    UserCountryModal
  },
  setup() {
    const { modalCountry } = storeToRefs(useAppStore())
    const { profile, userCountries } = storeToRefs(useAuthStore())
    const { openEditModalCountry } = useAppStore()
    const router = useRouter()
    const route = useRoute()

    const view = ref('global')
    const scrollcontainer = ref()
    const userChanged = ref(false)

    const guestAddCountryAlert = async () => {
      const alert = await alertController.create({
          header: 'Add country',
          subHeader: '',
          message: 'Start adding countries to your own profile',
          buttons: [
          {
              text: 'cancel',
              role: 'cancel',
          },
          {
              text: 'Login',
              role: 'login',
              handler: () => { router.push("/login") }
          }
          ]
      });
      await alert.present();
    }

    const changeUser = (username) => {
      if(profile.value && profile.value.username === username) view.value = "own"
      else {
        delete modalCountry.value.userCountry
        modalCountry.value.user = username
        view.value = "user"
        userChanged.value = true
        setTimeout(() => {
          userChanged.value = false
        },2000)
      }
    }

    const beforePresent = () => {
      const ownCountry = userCountries.value ? userCountries.value.find((x) => x.id == modalCountry.value.code) : false
      if(ownCountry) modalCountry.value.ownCountry = ownCountry
      
      const staticCountry = allCountries.find((x) => x.alpha2Code == modalCountry.value.code)
      if(staticCountry) modalCountry.value.staticCountry = staticCountry
      else console.log("error country not found:"+modalCountry.value.code)

      view.value = 'global'
      if(modalCountry.value.user) {
        view.value = 'user'
        if(profile.value && modalCountry.value.user === profile.value.username) view.value = 'own'
        //if(profile.value && modalCountry.value.user === profile.value.username && !ownCountry) view.value = 'global'
      }
    }

    watch(userCountries, async () => {
      const ownCountry = userCountries.value ? userCountries.value.find((x) => x.id == modalCountry.value.code) : false
      if(ownCountry) modalCountry.value.ownCountry = ownCountry
    })

    watch(scrollcontainer, async () => {
      if(scrollcontainer.value){
        scrollcontainer.value.$el.ontouchmove = (e) => {
          e.stopPropagation();
        }
      }
    })

    watch(modalCountry, async () => {
      if(modalCountry.value.code){
        await router.push(router.currentRoute.value.path+"#mdCountry"+modalCountry.value.code)
      } else {
        if(route.hash.startsWith("#mdCountry") && route.path.indexOf("/trips/") == -1 && route.path.indexOf("/contacts/") == -1 && route.path.indexOf("/explore/countries") == -1) {
          history.back()
        }
      }
    })

    addEventListener('popstate', () => { 
      if(!route.hash.startsWith("#mdCountry")) modalCountry.value = {}
    });

    return {
        modalCountry,
        view,
        beforePresent,
        allCountries,
        profile,
        checkmarkCircleOutline,
        addCircleOutline,
        earthOutline,
        openEditModalCountry,
        guestAddCountryAlert,
        scrollcontainer,
        changeUser,
        userChanged
    }
  }
});
