
import { defineComponent, ref, watch } from "vue";
import { Geolocation } from '@capacitor/geolocation';
import { IonButton, IonSpinner, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonToolbar, isPlatform, toastController, IonInput } from "@ionic/vue";
import { closeCircleOutline, locateOutline, mapOutline, searchOutline } from 'ionicons/icons';
import UserLocation from "@/components/UserLocation.vue"
import PickMapbox from "@/components/maps/PickMapbox.vue"

export default defineComponent({
  name: "LocationInput", 
  components: {
    IonHeader,
    IonModal,
    IonContent,
    IonToolbar,
    IonList,
    IonItem,
    IonIcon,
    UserLocation,
    PickMapbox,
    IonButton,
    IonSpinner,
    IonInput
  },
  props: ["location", "id", "onlyModal", "triggerOpen", "placeholder", "hidePrivacy"],
  emits: ["change", "close"],
  setup(props, context) {
    const errorStr = ref("")
    const gettingLocation = ref(false)
    const open = ref(false) 
    const input = ref("")
    const toolbar = ref()
    const content = ref()
    const inputel = ref()
    const modal = ref()
    const locationPicker = ref(false)
    const showPrivacyInfo = ref(false)
    const osmResults = ref<string[]>([])
    const osmLoading = ref(false)
    const mapboxglAccessToken = "pk.eyJ1IjoicGhvaGVpc2VsIiwiYSI6ImNsM2ZxMHdjNjA3anIzZHQ1a2xxankydTIifQ.50dyxfxEbCNMjgxDwvoCmQ"

    const updateLocation = (osmPlace) => {
        if(osmPlace){
            open.value = false
            context.emit("change", {countrycode: osmPlace.properties.countrycode, ...osmPlace})
        } else {
            context.emit("change", false)
        }
    }

    const reverseOSM = async (pos) => {
        osmResults.value = []
        osmLoading.value = true
        fetch('https://photon.komoot.io/reverse?lat='+(pos.lat ? pos.lat : pos.coords.latitude)+'&lon='+(pos.lng ? pos.lng : pos.coords.longitude)+'&lang=en&limit=5')
        //fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+(pos.lng ? pos.lng : pos.coords.longitude)+','+(pos.lat ? pos.lat : pos.coords.latitude)+'.json?access_token='+mapboxglAccessToken)
            .then(response => response.json())
            .then(async (data) => {
                const reverseResult = data.features
                if(!reverseResult || reverseResult.length<1){
                    console.log("no results from location!!")
                    osmLoading.value = false
                    const toast = await toastController
                        .create({
                            message: "Nothing found at this location.",
                            duration: 2000,
                            position: 'top',
                            buttons: [
                            {
                                side: 'end',
                                text: 'ok',
                                role: 'cancel',
                                handler: () => {
                                    console.log('Cancel clicked');
                                }
                            }
                            ]
                        })
                        toast.present();
                } else {
                    const searchFor = reverseResult[0].properties.city
                    fetch('https://photon.komoot.io/api/?q='+encodeURI(searchFor)+'&lang=en&limit=2')
                    //fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+encodeURI(searchFor)+'.json?access_token='+mapboxglAccessToken)
                        .then(response => response.json())
                        .then(data => {
                            osmResults.value = []
                            osmLoading.value = false
                            gettingLocation.value = false;
                            // if(pos.lat) osmResults.value = reverseResult.concat(data.features)
                            // else osmResults.value = data.features.concat(reverseResult)
                            osmResults.value = data.features.concat(reverseResult)
                            modal.value.$el.setCurrentBreakpoint(0.8)
                            showPrivacyInfo.value = false
                        })
                        .catch(e => {
                            osmLoading.value = false
                            console.log("fetch error:", e)
                        })                 
                }
            })
            .catch(async (e) => {
                console.log("fetch error", e)
                osmLoading.value = false
                const toast = await toastController
                    .create({
                        message: "Location service seams busy please try again later.",
                        duration: 3000,
                        position: 'top',
                        buttons: [
                        {
                            side: 'end',
                            text: 'ok',
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel clicked');
                            }
                        }
                        ]
                    })
                    toast.present();
            })
    }

    const searchOSM = async () => {
        osmResults.value = []
        osmLoading.value = true
        const checkVal = input.value
        setTimeout(() => {
            if(checkVal == input.value) {
                fetch('https://photon.komoot.io/api/?q='+encodeURI(input.value)+'&lang=en&limit=10')
                //fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+encodeURI(input.value)+'.json?access_token='+mapboxglAccessToken)
                    .then(response => response.json())
                    .then(data => {
                        osmResults.value = []
                        gettingLocation.value = false;
                        osmLoading.value = false
                        osmResults.value = data.features
                        modal.value.$el.setCurrentBreakpoint(0.8)
                        showPrivacyInfo.value = false
                    })
                    .catch(async (e) => {
                        osmLoading.value = false
                        console.log("fetch error", e)
                        const toast = await toastController
                            .create({
                                message: "Location service seams busy please try again later.",
                                duration: 3000,
                                position: 'top',
                                buttons: [
                                {
                                    side: 'end',
                                    text: 'ok',
                                    role: 'cancel',
                                    handler: () => {
                                        console.log('Cancel clicked');
                                    }
                                }
                                ]
                            })
                            toast.present();
                    })
            }
        },200)
    }

    const getLocationApp = async () => {
        const coordinates = await Geolocation.getCurrentPosition();
        reverseOSM(coordinates)
    };

    const getLocationWeb = () => {
        if(!("geolocation" in navigator)) {
            errorStr.value = 'Geolocation is not available.';
            return;
        }
        gettingLocation.value = true;
        navigator.geolocation.getCurrentPosition(pos => {
            reverseOSM(pos)
        }, err => {
            gettingLocation.value = false;
            errorStr.value = err.message;
        })
    }

    const getLocation = () => {
        if(isPlatform("capacitor")){
            getLocationApp()
        } else {
            getLocationWeb()
        }
    }

    watch(open, async () => {
        input.value = ""
        osmResults.value = []
        setTimeout(() => {
            if(open.value) inputel.value.$el.setFocus()
        }, 600)
        if(!open.value) context.emit("close")
    })

    watch(props, async () => {
      open.value = props.triggerOpen
    })

    return {
      gettingLocation,
      open,
      toolbar,
      content,
      input,
      inputel,
      modal,
      osmResults,
      searchOSM,
      updateLocation,
      closeCircleOutline,
      locateOutline,
      mapOutline,
      locationPicker,
      reverseOSM,
      getLocation,
      showPrivacyInfo,
      osmLoading,
      searchOutline
    };
  },
});
