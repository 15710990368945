import { initializeApp } from "firebase/app";
import {
    initializeAuth,
    indexedDBLocalPersistence,
    getAuth,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendEmailVerification,
} from "firebase/auth";
import { CustomProvider, initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  getDocs,
  collection,
  onSnapshot,
  deleteField,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  increment,
  where,
  limit,
  DocumentSnapshot,
  QuerySnapshot,
  startAt,
  endAt,
  startAfter,
  enableIndexedDbPersistence,
  writeBatch
} from "firebase/firestore";
import { getDatabase, onDisconnect, onValue, push, ref, set, serverTimestamp } from "firebase/database";
import { connectStorageEmulator, deleteObject, getDownloadURL, getStorage, ref as ref_storage, uploadBytesResumable, uploadString } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';
// import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { FirebaseAppCheck } from '@capacitor-firebase/app-check';
import { isPlatform } from "@ionic/vue";

const appId = "1:752949869194:web:0e1d0a2b77ad8b32c68b73"
const apiKey = "AIzaSyDSeUVKyN9aZOiTTcl9_rLJgcFve4hZQ_U"
/*if(isPlatform("capacitor")){
  appId = "1:752949869194:android:b14898af0a9849fac68b73"
  apiKey = "AIzaSyDzfTP5ZxkjUAuDmHTo7Se8jFzXSHOm1_U"
}*/

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "manyways.app",
  projectId: "wbb-app",
  storageBucket: "wbb-app.appspot.com",
  messagingSenderId: "752949869194",
  appId: appId,
  databaseURL: "https://wbb-app-default-rtdb.europe-west1.firebasedatabase.app",
  measurementId: "G-LH0PSXPLC5"
};

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const app = initializeApp(firebaseConfig);
//if (isPlatform("capacitor")) {
//    initializeAuth(app, {
//        persistence: indexedDBLocalPersistence
//    });
//}

export const functions = getFunctions(app)

export let analytics:any = false
// analytics.setAnalyticsCollectionEnabled(false)

//if(!isPlatform("capacitor") && location.hostname === "localhost") {
  //2DO
if(!isPlatform("capacitor") && location.hostname === "localhost") {
  console.log("FIREBASE_APPCHECK_DEBUG_TOKEN")
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}


if(isPlatform("capacitor")){
  console.log("capacitor app check")
  try {
    FirebaseAppCheck.initialize({
      // enable debugging if in staging or dev environments. Default is false.
      debug: false,
      isTokenAutoRefreshEnabled: true
    })

    const appCheckCustomProvider = new CustomProvider({
      getToken: async () => {
        console.log("appCheckCustomProvider getToken")
        const { token, expireTimeMillis } = await FirebaseAppCheck.getToken();
        return new Promise((resolve, _reject) => {
          const appCheckToken:any = {
            token,
            expireTimeMillis
          };
          resolve(appCheckToken);
        });
      }
    });
    
    // eslint-disable-next-line
    const appCheck = initializeAppCheck(app, {
      provider: appCheckCustomProvider,
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    });
    console.log("appCheck:", appCheck)
  } catch (err) {
    // log any errors
    console.error("capacitor app check error", err);
  }
 
} else { 
  console.log("web app check")
  // eslint-disable-next-line
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeG27AhAAAAALtwgKiaOQE6yVz92wdzTR-PVady'),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
  console.log("appCheck:", appCheck)
}



export const consentAnalytics = () => {
  if(!analytics) analytics = getAnalytics(app);
}

export const dismissAnalytics = () => {
  if(analytics) analytics = false;
}

const db = getFirestore(app);
const rtdb = getDatabase(app);
let auth
if (isPlatform('capacitor')) {
    auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
    })
} else {
    auth = getAuth()
}

//const auth = getAuth(app);
auth.languageCode = "en"
// let messaging:any = false
// if(!isPlatform("capacitor")){
//   //isSupported() //not working?
//   messaging = getMessaging(app);
// }

enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

export const fbMessagingToken = async () => {
  // return getToken(messaging)
  return false
}

export const firestoreListener = onSnapshot
export const deleteDbField = deleteField()

const getDocuments = query => {
	return getDocs(query).then(docs => {
		return { data: formatQueryDataArray(docs), docs: docs.docs }
	})
}

const getDocument = ref => {
	return getDoc(ref).then(doc => formatQueryDataObject(doc))
}

export const addDocument = (ref, data) => {
	return addDoc(ref, data)
}

const setDocument = (path, docId, data, merge) => {
	return setDoc(doc(db, path, docId), data, { merge: merge })
}

const updateDocument = (ref, data) => {
	return updateDoc(ref, data)
}

const deleteDocument = (ref, docId) => {
	return deleteDoc(doc(db, ref, docId))
}

const uploadImage = (path, bulb) => {
  const storage = getStorage();
  return uploadString(ref_storage(storage, path), bulb, 'data_url')
}

const deleteImage = (path) => {
  const storage = getStorage()
  const desertRef = ref_storage(storage, path)
  return deleteObject(desertRef)
}

// APP
export const listenAppInfo = (callback) => {
	return firestoreListener(doc(db, 'app/info'), info => {
		callback(formatQueryDataObject(info))
	})
}

// ADMIN ACTIVITIES
const activitiesRef = collection(db, 'activities')

export const listenActivities = (newerThen, callback) => {
  return firestoreListener(query(activitiesRef, orderBy('createdOn', 'desc'), where('createdOn', '>', newerThen)), activity => {
    callback(formatQueryDataArray(activity))
	})
}

export const getActivities = (lastSnapshot:any=false) => {
  const constraints:any = []
  constraints.push(orderBy('createdOn', 'desc'))
  constraints.push(limit(10))
  if(lastSnapshot) constraints.push(startAfter(lastSnapshot.get("createdOn")))
	return getDocuments(query(activitiesRef, ...constraints))
}


// USERS
const usersRef = collection(db, 'users')

const userRef = userId => {
	return doc(db, 'users', userId)
}

const usernameRef = username => {
  return doc(db, 'usernames', username)
}

const userSettingsRef = userId => {
	return doc(db, 'users', userId, 'private/settings')
}

export const getAllUsers = () => {
	return getDocuments(query(usersRef))
}

export const findUser = (input) => {
  if(input.length>0) return getDocuments(query(usersRef, orderBy('username'), startAt(input), endAt(input+"\uf8ff")))
  else return Promise.reject()
}

export const listenUser = (userId, callback) => {
	return firestoreListener(userRef(userId), user => {
		callback(formatQueryDataObject(user))
	})
}

export const getUserId = username => {
  return getDocument(usernameRef(username))
}

export const getUser = userId => {
	return getDocument(userRef(userId))
}

export const addUser = data => {
	return addDocument(usersRef, data)
}

export const deleteUser = userId => {
  return deleteDocument('users/', userId)
}

export const deleteUsername = username => {
  return deleteDocument('usernames/', username)
}

export const updateUser = async (userId, data) => {
  if(data.photoUrl && data.photoUrl != "" && !data.photoUrl.startsWith('https://')) {
    return uploadImage(data.username, data.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        updateDocument(userRef(userId), data)
      })
     });
  } else {
    return updateDocument(userRef(userId), data)
  }
}

export const listenUserSettings = (userId, callback) => {
	return firestoreListener(userSettingsRef(userId), settings => {
		callback(formatQueryDataObject(settings))
	})
}

export const updateUserSettings = (userId, data) => {
	return updateDocument(userSettingsRef(userId), data)
}

export const initUserStatus = (userId) => {
  // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
  // any time that connectionsRef's value is null (i.e. has no children) I am offline
  const myConnectionsRef = ref(rtdb, 'statusNew/'+userId+'/connections');
  
  // stores the timestamp of my last disconnect (the last time I was seen online)
  const lastOnlineRef = ref(rtdb, 'statusNew/'+userId+'/lastOnline');

  //chat status
  const chatConnectionRef = ref(rtdb, 'statusChat/'+userId);
  const isOfflineData = {
    state: 'offline',
    lastChanged: serverTimestamp()
  }
  const isOnlineData = {
    state: 'online',
    lastChanged: serverTimestamp()
  }
  //
  //old status
  const oldConnectionRef = ref(rtdb, 'status/'+userId);

  const connectedRef = ref(rtdb, '.info/connected');
  onValue(connectedRef, (snap) => {
    console.log("userstatus onvalue")
    if (snap.val() === true) {
      // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
      const con = push(myConnectionsRef);

      // When I disconnect, remove this device
      onDisconnect(con).remove();

      // Add this device to my connections list
      // this value could contain info about the device or a timestamp too
      set(con, true);

      // When I disconnect, update the last time I was seen online
      onDisconnect(lastOnlineRef).set(serverTimestamp());

      //old status
      onDisconnect(oldConnectionRef)
        .set("offline")
        .then(() => {
					set(oldConnectionRef, "online")
				})      //

      //chat status
      /*
      onDisconnect(chatConnectionRef)
        .set(isOfflineData)
        .then(() => {
					set(chatConnectionRef, isOnlineData)
				})
      */
    }
  });
}

export const getCountryUsers = async(country, photos) => {
  const orderOptions = ["lastPresence", "activityCount", "bio"] //to randomize results
  if(photos) {
    return getDocuments(query(usersRef, orderBy(orderOptions[Math.floor(Math.random() * orderOptions.length)]), limit(3), where("statsPhotoCountries", "array-contains", country.toLowerCase()), where("public", "==", true)))
      .then(async (users) => {
        for(const user of users.data) {
          user.countryData = await getUserCountry(user.id, country.toLowerCase())
        }
        return users
      })
  } else {
    return getDocuments(query(usersRef, orderBy(orderOptions[Math.floor(Math.random() * orderOptions.length)]), limit(3), where("statsVisitedCountries", "array-contains", country.toLowerCase()), where("public", "==", true)))
  }
}

let lastUserSnapshot:any = false

export const getUsers = async(init, userFilterTags, userFilterCountry) => {
  const constraints:any = []
  constraints.push(where("public", "==", true))
  constraints.push(orderBy("username", "asc"))
  constraints.push(limit(2))
  if(userFilterTags && userFilterTags.length > 0){
    constraints.push(where('statsExps', 'array-contains-any', userFilterTags))
  }
  if(userFilterCountry){
      constraints.push(where('statsVisitedCountries', 'array-contains', userFilterCountry.toLowerCase()))
      //constraints.push(where('statsPlannedCountries', 'array-contains', this.userFilterCountry.toLowerCase()))
  }
  if((!userFilterTags || userFilterTags.length < 1) && !userFilterCountry){
      constraints.push(where('selected', '==', true))
  }
  if(!init && lastUserSnapshot) {
      constraints.push(startAfter(lastUserSnapshot.get("username")))
  }
  return getDocuments(query(usersRef, ...constraints))
    .then(async (snap) => {
      lastUserSnapshot = snap.docs[snap.docs.length - 1]
      if(userFilterCountry) {
        for(const user of snap.data) {
          user.countryData = await getUserCountry(user.id, userFilterCountry.toLowerCase())
        }
        return snap
      }
      return snap
    })
}

export const getAUsers = async() => {
  const constraints:any = []
  constraints.push(orderBy("username", "asc"))
  return getDocuments(query(usersRef, ...constraints))
}

export const setAppleRefreshToken = async (userId, code) => {
  console.log("setAppleRefreshToken", code)
  return setDocument('users/' + userId + '/private', 'appleRefreshToken', {
    code: code,
    createdOn: new Date()
  }, true)
}

export const addPrivateFcmToken = async(userId, token) => {
  return setDocument('users/'+userId+'/private/fcm/tokens', token, {
    token: token,
    createdOn: new Date()
  }, false)
}

export const deletePrivateFcmToken = async(userId, token) => {
  return deleteDocument('users/'+userId+'/private/fcm/tokens', token)
}

export const addWaitinglist = async(submittedEmail, submittedLink) => {
  return setDocument('waitingList', submittedEmail, {
    email: submittedEmail,
    link: submittedLink,
    createdOn: new Date()
  }, false)
}

export const addActivity = async(userId, activity) => {
  /*
    List of types:
      - country
      - userProfile
      - trip
    List of activities:
      - update
      - planned
      - visited
      - interview
      - start
      - end
      - create
  */
  await addDocument(collection(db, 'users/'+userId+'/activities'), {
    createdOn: new Date(),
    userId: userId,
    type: activity.type,
    activity: activity.activity,
    countryCode: activity.countryCode ? activity.countryCode : null,
    country: activity.country ? activity.country : null,
    location: activity.location ? activity.location : null,
    title: activity.title ? activity.title : null,
    actId: activity.id ? activity.id : null
  }).then(() => {
      if(analytics) logEvent(analytics, "add_activity")
      //console.log('added activity '+activity.type)
      const decrement = increment(+1)
      updateUser(userId, {
        lastActivity: new Date(),
        activityCount : decrement,
        triggerAlgoliaSync: Date.now()
      })
    })
    .catch( error => {
      console.log(error)
    })
}

//INVITES
const invitesRef = collection(db, 'invites')

export const getAInvites = async () => {
  const constraints:any = []
  //constraints.push(orderBy("username", "asc"))
  return getDocuments(query(invitesRef, ...constraints))
}

export const listenUserInvites = (username, callback) => {
	return firestoreListener(query(invitesRef, where('by', '==', username)), invites => {
		callback(formatQueryDataArray(invites))
	})
}

export const addInvite = async (invite, username) => {
  return setDocument('invites', invite, {
    createdOn: new Date(),
    by: username,
    once: true
  }, false)  
}

export const deleteInvite = async (invite: string) => {
  return deleteDocument('invites', invite)
}

//INTERVIEWS
const interviewRef = collection(db, 'userInterviews')

export const getInterviewQuestions = () => {
  return getDocuments(query(interviewRef))
}

export const updateInterviewQuestion = async (userProfile, question) => {
  if(question.place && Object.keys(question.place).length > 0 ){
    question.country = question.place.countrycode.toLowerCase()
  }
  question.lastUpdate = new Date()
  let interview = userProfile.interview
  let foundExisting = false
  if(interview && interview.length > 0){
    let x = 0
    for (const quest of interview) {
      if (quest.id === question.id) {
        interview[x] = question
        foundExisting = true
        break;
      }
      x++
    }
    if(!foundExisting) interview.push(question)
  } else {
    interview = new Array(question)
  }
  const userStatsInterview:any = []
  interview.forEach(question => {
    userStatsInterview.push(question.id)
  })
  if(question.photoUrl && question.photoUrl != "" && !question.photoUrl.startsWith('https://')) {
    return uploadImage(userProfile.username+'/interview/'+question.id, question.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        question.photoUrl = url
        setDocument('users', userProfile.id, {
          interview: interview,
          statsInterview: userStatsInterview,
          statsInterviewLatest: question.id,
          statsInterviewLastUpdate: question.lastUpdate
        }, true)
      })
     });
  } else {
    await deleteImage(userProfile.username+'/interview/'+question.id).catch(() => { console.log("image error delete") })
    return setDocument('users', userProfile.id, {
      interview: interview,
      statsInterview: userStatsInterview,
      statsInterviewLatest: question.id,
      statsInterviewLastUpdate: question.lastUpdate
    }, true)
  }
}

//EXPS
const expsRef = () => {
	return collection(db, 'exps/')
}

export const getAllExps = () => {
	return getDocuments(query(expsRef()))
}

export const addExp = async (exp) => {
  return setDocument('exps', exp, {
    createdOn: new Date(),
    name: exp,
    count: 0
  }, false)  
}

export const deleteExp = async (exp: string) => {
  return deleteDocument('exps', exp)
}


//OPENS OPEN FOR
const opensRef = () => {
	return collection(db, 'opens/')
}

export const getAllOpens = () => {
	return getDocuments(query(opensRef()))
}

export const addOpen = async (open) => {
  return setDocument('opens', open, {
    createdOn: new Date(),
    name: open,
    count: 0
  }, false)  
}

export const deleteOpen = async (open: string) => {
  return deleteDocument('opens', open)
}

//UESER EXPORT
const userExportRef = userId => {
  return doc(db, 'users', userId, 'private/export')
}
const userExportExportsRef = userId => {
	return collection(db, 'users/'+userId+'/private/export/exports')
}

export const listenUserExport = (userId, callback) => {
	return firestoreListener(userExportRef(userId), settings => {
		callback(formatQueryDataObject(settings))
	})
}

export const listenUserExportExports = (userId, callback) => {
	return firestoreListener(query(userExportExportsRef(userId), orderBy('createdOn', 'desc')), posts => {
		callback(formatQueryDataArray(posts))
	})
}

export const addExport = async(userId, data) => {
  return addDocument(userExportExportsRef(userId), data)
}

//USER FEED
const userFeedNotificationsRef = userId => {
	return collection(db, 'users/'+userId+'/private/feed/notifications')
}

const userFeedNotificationRef = (userId, notificationId) => {
	return doc(db,  'users/'+userId+'/private/feed/notifications', notificationId)
}

const userFeedRef = userId => {
  return doc(db, 'users', userId, 'private/feed')
}

export const listenUserFeed = (userId, callback) => {
	return firestoreListener(userFeedRef(userId), settings => {
		callback(formatQueryDataObject(settings))
	})
}

export const listenUserFeedNotifications = (userId, callback) => {
  //return firestoreListener(query(userFeedNotificationsRef(userId), limit(10)), countries => {
	return firestoreListener(query(userFeedNotificationsRef(userId), orderBy('createdOn', 'desc')), posts => {
		callback(formatQueryDataArray(posts))
	})
}

export const seenNotification = (userId, notificationId) => {
  return updateDocument(userFeedNotificationRef(userId, notificationId), { seen: true })
}

export const deleteNotification = (userId, notificationId) => {
  return deleteDocument('users/'+userId+'/private/feed/notifications', notificationId)
}

export const resetNotification = (userId) => {
  return updateDocument(userFeedRef(userId), { unread: 0 })
}

export const addNotification = async (username, data) => {
  const user = await getUserId(username)
  console.log(user)
  return addDocument(userFeedNotificationsRef(user.uid), data)
}

//TRIPS
const tripsRef = () => {
	return collection(db, 'trips')
}
const tripRef = (tripId) => {
  return doc(db, 'trips', tripId)
}
const tripStopsRef = (tripId) => {
  return collection(db, 'trips/'+tripId+'/stops')
}
const tripStopRef = (tripId, stopId) => {
  return doc(db, 'trips/'+tripId+'/stops/', stopId)
}

const getCountryFromPlace = (place) => {
  if(place.id){
      if(place.id.includes("country.")) return place.properties.short_code
      else {
          return place.context.find(x => x.id.includes("country.")).short_code
      }
  } else {
      return place.countrycode
  }
}

export const getTrip = (tripId) => {
	return getDocument(tripRef(tripId))
}

export const getTrips = (userId) => {
  return getDocuments(query(tripsRef(), where("userId", "==", userId)))
}

export const listenUserTrips = (userId, callback) => {
  return firestoreListener(query(tripsRef(), where('userId', "==", userId)), trips => {
    callback(formatQueryDataArray(trips))
	})
}

export const getStops = (tripId, userId) => {
  return getDocuments(query(tripStopsRef(tripId), where("userId", "==", userId)))
}

export const deleteTrip = async (tripId, userId) => {
  await deleteStops(tripId, userId);
  return deleteDocument('trips/', tripId)
}

export const deleteStops = async (tripId, userId) => {
  const stops = await getStops(tripId, userId)
  const stopsBatch = writeBatch(db)
  stops.data.forEach(stop => {
    stopsBatch.delete(tripStopRef(tripId, stop.id))
  })
  return stopsBatch.commit();
}

export const deleteStop = (tripId, stopId) => {
  return deleteDocument(tripStopsRef(tripId), stopId)
}

export const addTrip = async(user, data) => {
  data.createdOn = new Date()
  data.lastActivity = new Date()
  data.userId = user.id
  data.username = user.username
  const stops = JSON.parse(JSON.stringify(data.stops))
  const countries:any = []
  stops.forEach(stop => {
    if(stop.place) {
      countries.push(getCountryFromPlace(stop.place).toLowerCase())
    }
  })
  data.countries = [...new Set(countries)]
  // removing photo stuff to just add the plain dataset and add media with an update later
  if(data.photoUrl) delete data.photoUrl
  delete data.stops
  data.published = false
  const newTrip = await addDocument(tripsRef(), data)
  return newTrip
}

export const updateTrip = async (user, data, oldStopPhotos) => {
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key])
  data.lastActivity = new Date()
  const stops = JSON.parse(JSON.stringify(data.stops))
  const countries:any = []
  stops.forEach(stop => {
    if(stop.place) {
      countries.push(getCountryFromPlace(stop.place).toLowerCase())
    }
  })
  data.countries = [...new Set(countries)]
  //delete data.stops
  await deleteStops(data.id, user.id)
  //add stops
  // const stopsBatch = writeBatch(db)
  const keepPhotos:any = []
  for (const stop of stops) {
    if(stop.photoUrl && oldStopPhotos.includes(stop.photoRef)) {
      //console.log("don't delete: "+stop.photoRef)
      keepPhotos.push(stop.photoRef)
    }
    stop.createdOn = new Date()
    stop.lastActivity = new Date()
    stop.userId = user.id
    stop.username = user.username
    stop.tripId = data.id
    if(stop.photoUrl && !stop.photoUrl.startsWith('https://')) {
      const time = Date.now();
      await uploadImage(data.username+'/trips/'+data.id+'/'+time, stop.photoUrl).then( async (snapshot) => {
        await getDownloadURL(snapshot.ref).then((url) => {
          stop.photoUrl = url
          stop.photoRef = data.username+'/trips/'+data.id+'/'+time
          //console.log("upload complete"+url)
          // stopsBatch.set(doc(tripStopsRef(data.id)), stop)
        })
      });
    } else {
      stop.photoRef = stop.photoUrl != "" && stop.photoUrl != null && stop.photoUrl != false && stop.photoUrl != undefined ? stop.photoRef : false
      // stopsBatch.set(doc(tripStopsRef(data.id)), stop)
    }
    //console.log("data to store:", stop)
  }
  // await stopsBatch.commit();
  // console.log("delete old photos:")
  // console.log(keepPhotos)
  // console.log(oldStopPhotos)
  for (const photo of oldStopPhotos) {
    if(photo!="" && photo!=false && photo!=undefined && !keepPhotos.includes(photo)) {
      // console.log(photo)
      await deleteImage(photo).catch(() => { console.log("image error delete") })
    }
  }
  //
  data.stops = stops
  //upload trip photo
  if(data.photoUrl && !data.photoUrl.startsWith('https://')) {
    await uploadImage(data.username+'/trips/'+data.id, data.photoUrl).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        updateDocument(tripRef(data.id), data)
      })
    });
  } else {
    updateDocument(tripRef(data.id), data)
  }
  const newStats = user.statsTrips ? user.statsTrips : []
  //remove to make space for updated stats:
  if(newStats.findIndex(x => x.id == data.id)>=0){
    newStats.splice(newStats.findIndex(x => x.id == data.id), 1)
  }
  if(data.published) {
    //add user stats
    const statTrip = JSON.parse(JSON.stringify(data))
    delete statTrip.stops //delete stops for user stats to save size!
    statTrip.id = data.id
    statTrip.countStops = stops.length
    statTrip.countries = [...new Set(countries)]
    newStats.push(statTrip)
  }
  return updateUser(user.id, {
    statsTrips: newStats
  })
}

//USER CONTACTS
const userContactsRef = (userId) => {
  return collection(db, 'users/'+userId+'/contacts')
}

const userContactRef = (userId, contactId) => {
  return doc(db, 'users/'+userId+'/contacts/', contactId)
}

export const listenUserContacts = (userId, callback) => {
  return firestoreListener(query(userContactsRef(userId), orderBy('name', 'asc')), contacts => {
    const upper = formatQueryDataArray(contacts).map(element => {
      element.name = element.name.charAt(0).toUpperCase() + element.name.slice(1);
      return element;
    });
    upper.sort((a, b) => a.name.localeCompare(b.name));
    callback(upper)
    /*
    const contactsArray = <any>[]
    contacts.forEach(doc => {
      const contact = formatQueryDataObject(doc)
      console.log("start for each contact")
      console.log(contact)
      // get and set tags from references
      const tags = <any>[]
      if(contact.tags) {
        contact.tags.forEach( (el) => {
          console.log(el)
          el.get().then(res => {
            const tag = res.data()
            if(tag!=undefined){
              tag.id = res.id
              tags.push(tag)  
            }
          })
        })
      } 
      contact.tags = tags
      contactsArray.push(contact)
      
    })
		callback(contacts)
    */
	})
}

export const addContact = async(userId, data, username) => {
  if(data.photoUrl && !data.photoUrl.startsWith('https://')) {
    //add document to 'posts' collection with auto id
    const newItem = await addDocument(userContactsRef(userId), data)
    return uploadImage(username+'/contacts/'+newItem.id, data.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        updateDocument(userContactRef(userId, newItem.id), data)
      })
     });
  }
  else {
    return addDocument(userContactsRef(userId), data)
  }
}

export const updateContact = (userId, contactId, data, username) => {
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key])
  if(data.photoUrl && data.photoUrl != "" && !data.photoUrl.startsWith('https://')) {
    return uploadImage(username+'/contacts/'+contactId, data.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        setDocument('users/'+userId+'/contacts/', contactId, data, false)
        //updateDocument(userContactRef(userId, contactId), data)
      })
     });
  } else {
    return setDocument('users/'+userId+'/contacts/', contactId, data, false)
  }
}

export const deleteContact = async (userId, username, id) => {
  await deleteImage(username+'/contacts/'+id).catch(() => { console.log("image error delete") })
  return deleteDocument('users/'+userId+'/contacts/', id)
}

//report
export const addReport = async(username, report) => {
  await addDocument(collection(db, 'reports'), {
    createdOn: new Date(),
    autorUsername: username,
    username: report.username,
    content: report.content,
    link: report.link,
    text: report.text
  })
}

//USER BLOCKING
const userBlockedRef = userId => {
	return collection(db, 'users/'+userId+'/blocked')
}

const userBlockedByRef = userId => {
	return collection(db, 'users/'+userId+'/blockedBy')
}

export const listenUserBlocked = (userId, callback) => {
  return firestoreListener(query(userBlockedRef(userId), orderBy('username', 'asc')), blocked => {
    callback(formatQueryDataArray(blocked))
	})
}

export const listenUserBlockedBy = (userId, callback) => {
  return firestoreListener(query(userBlockedByRef(userId)), blockedBy => {
    callback(formatQueryDataArray(blockedBy))
	})
}

export const addBlock = async (userId, username) => {
  const user = await getUserId(username)
  return setDocument('users/'+userId+'/blocked', user.uid, {
    createdOn: new Date(),
    id: user.uid,
    username: username,
  }, false)  
}

export const deleteBlock = (userId, blockedUser) => {
  return deleteDocument('users/'+userId+'/blocked/', blockedUser)
}

//USER FOLLOWER-ING
const userFollowerRef = userId => {
	return collection(db, 'users/'+userId+'/follower')
}
const userFollowerPendingRef = userId => {
	return collection(db, 'users/'+userId+'/followerPending')
}
const userFollowingRef = userId => {
	return collection(db, 'users/'+userId+'/following')
}

export const getUserFollower = userId => {
	return getDocuments(query(userFollowerRef(userId)))
}
export const getUserFollowerPending = userId => {
	return getDocuments(query(userFollowerPendingRef(userId)))
}
export const getUserFollowing = userId => {
	return getDocuments(query(userFollowingRef(userId)))
}

export const listenUserFollower = (userId, callback) => {
  return firestoreListener(query(userFollowerRef(userId), orderBy('username', 'asc')), follower => {
    callback(formatQueryDataArray(follower))
	})
}

export const listenUserFollowerPending = (userId, callback) => {
  return firestoreListener(query(userFollowerPendingRef(userId), orderBy('username', 'asc')), follower => {
    callback(formatQueryDataArray(follower))
	})
}

export const listenUserFollowing = (userId, callback) => {
  return firestoreListener(query(userFollowingRef(userId), orderBy('username', 'asc')), following => {
    callback(formatQueryDataArray(following))
	})
}

export const addFollowing = (userId, user) => {
  return setDocument('users/'+userId+'/following', user.id, {
    createdOn: new Date(),
    username: user.username,
    confirmed: false
  }, false)  
}

export const confirmFollower = (userId, user) => {
  return setDocument('users/'+userId+'/follower', user.id, {
    createdOn: new Date(),
    username: user.username
  }, false)  
}

export const deleteFollowing = (userId, user) => {
  return deleteDocument('users/'+userId+'/following/', user.id)
}

export const deleteFollower = (userId, user) => {
  deleteDocument('users/'+userId+'/follower/', user.id)
  deleteDocument('users/'+userId+'/followerPending/', user.id)
}

//USER COUNTRIES
const userCountriesRef = userId => {
	return collection(db, 'users/'+userId+'/countries')
}

const userCountriesPrivateRef = userId => {
	return collection(db, 'users/'+userId+'/countriesPrivate')
}

const userCountryRef = (userId, countryId) => {
	return doc(db,  'users/'+userId+'/countries', countryId.toUpperCase())
}

const userCountryPrivateRef = (userId, countryId) => {
	return doc(db,  'users/'+userId+'/countriesPrivate', countryId.toUpperCase())
}

export const getUserCountries = userId => {
	return getDocuments(query(userCountriesRef(userId)))
}

export const getUserCountry = (userId, country) => {
	return getDocument(userCountryRef(userId, country))
}

export const listenUserCountries = (userId, callback) => {
	return firestoreListener(userCountriesRef(userId), countries => {
		callback(formatQueryDataArray(countries))
	})
}

export const listenUserPrivateCountries = (userId, callback) => {
	return firestoreListener(userCountriesPrivateRef(userId), countries => {
		callback(formatQueryDataArray(countries))
	})
}

export const addUserCountry = (userId, username, data) => {
  if(data.photoUrl && data.photoUrl != "" && !data.photoUrl.startsWith('https://')) {
    return uploadImage(username+'/countries/'+data.country, data.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        return setDocument('users/'+userId+'/countries', data.country.toUpperCase(), data, false)
      })
     });
  } else {
    return setDocument('users/'+userId+'/countries', data.country.toUpperCase(), data, false)
  }
}

export const updateUserCountry = async (userId, username, data) => {
  if(data.photoUrl && data.photoUrl != "" && !data.photoUrl.startsWith('https://')) {
    return uploadImage(username+'/countries/'+data.country, data.photoUrl).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        data.photoUrl = url
        return setDocument('users/'+userId+'/countries', data.id.toUpperCase(), data, true)
      })
     });
  } else {
    if(data.photoUrl=="") await deleteImage(username+'/countries/'+data.country).catch(() => { console.log("image error delete") })
    return setDocument('users/'+userId+'/countries', data.id.toUpperCase(), data, true)
  }
}

export const updateUserCountryPrivate = (userId, data) => {
	return setDocument('users/'+userId+'/countriesPrivate', data.id, data, true)
}

export const deleteUserCountry = async (userId, username, code) => {
  await deleteImage(username+'/countries/'+code.toUpperCase()).catch(() => { console.log("image error delete") })
  return deleteDocument('users/'+userId+'/countries/', code)
}

export const countCountry = (userId, content) => {
  return setDocument('users/'+userId+'/countries', content.country.toUpperCase(), {
    [content.where+'Contacts']: increment(content.direction)
  }, true)
}

// COUNTRIES
const countriesRef = collection(db, 'countries')

const countryRef = (countryCode: string) => {
	return doc(db, 'countries', countryCode)
}

export const getAllCountries = () => {
	return getDocuments(query(countriesRef, where("visitedCount", ">", 0)))
}

export const getCountry = (countryCode: string) => {
  return getDocument(countryRef(countryCode.toUpperCase()))
}


//Auth
export const getInvite = async (invite: string) => {
  return getDocument(doc(db, 'invites', invite.toLowerCase()))
}

export const fbSignIn = async (email: string, password: string) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  return response;
};


export const fbSignOut = async () => {
  await signOut(auth)
    .then(() => {
        console.log("signOut success")
    }).catch((error) => {
        console.log("signOut error", error)
    })
  return true;
};

export const fbAuthStateListener = (callback: any) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

export const fbCreateAccount = async (data: {email:string, password:string}) => {
  const { user } = await createUserWithEmailAndPassword(auth, data.email, data.password)
    .catch( (error) => {
      throw error;
    })
};

export const fbResendVerification = async (user) => {
  return sendEmailVerification(user)
}

export const fbCreateUser = async (user, data, inv) => {
  return setDocument('users', user.uid, {
    createdOn: new Date(),
    username: data.username.toLowerCase(),
    name: data.first,
    lastname: data.last,
    country: null,
    photo: "",
    public: true,
    bio: "",
    photoUrl: "",
    lastActivity: 0,
    lastPresence: 0,
    activityCount: 0,
    inviteBy: inv.by
  }, false)
    .then(async () => {
      //save more stuff like settings and so on
      return setDoc(userSettingsRef(user.uid), {
        notificationFollower: [true, true],
        notificationFollowingActivity: [true, true],
        notificationApp: [true, true],
        notificationFunfact: [true, true],
        notificationBlog: [true, true],
        notificationExport: [true, true],
        notificationInvite: [true, true],
        notificationFeedReminder: [true, true],
        notificationTips: [true, true],
        notificationTrips: [true, true]
      })
        .then(async () => {
          await setDoc(userFeedRef(user.uid), { 
            counter: 0,
            unread: 0 
          })
          return setDoc(doc(db, "usernames", data.username.toLowerCase()), { uid: user.uid })
            .then( () => {
              //double opt in
              if(user.providerData[0].providerId == "password"){
                console.log("username saved, send email verification")
                return sendEmailVerification(user)  
              } else {
                return new Promise((resolve, reject) => { resolve(true) })
              }
            })
            .catch( (error) => {
              console.log('saving username error')
              if(user.providerData[0].providerId == "password"){
                user.delete()
                  .catch( (e) => {
                    throw e;
                  })
              }
              throw error;
            })
        })
      })
      .catch(e => {
        console.log("saving user settings error")
        console.log(e)
        if(user.providerData[0].providerId == "password"){
          user.delete()
            .catch( (e) => {
              throw e;
            })
        }
        throw e;
      })
    .catch(e => {
      console.log("username is taken catch", user)
      console.log(e)
      //delete user if username taken
      //security is set by firestore rules
      if(user.providerData[0].providerId == "password"){
        user.delete()
          .catch( (e) => {
            throw e;
          })
      }
      console.log("before catch")
      throw Error('Username is already taken!');
    })
};

export const queryObjectCollection = async ({
  collectionName,
}: {
  collectionName: string;
}) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const results: any[] = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return results;
};

export const fbCollectionListener = (collectionName: string, callback: any) => {
  // eslint-disable-next-line
  const unsubscribe = onSnapshot(
    collection(db, collectionName),
    (snapshot) => {
      const results: any[] = [];
      snapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      callback(results);
    },
    (error) => {
      console.log("Error Listening To Collection: " + collectionName, error);
    }
  );
};

export { app, db, auth };

const formatQueryDataObject = queryData => {
	return { ...queryData.data(), id: queryData.id, _id: queryData.id }
}

const formatQueryDataArray = queryDataArray => {
	const formattedData = <any[]>[]

	queryDataArray.forEach(data => {
		formattedData.push(formatQueryDataObject(data))
	})
	return formattedData
}


