
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
    name: "ProfileMapboxStatic",
    props: ['usernameProfile', 'height', 'width', 'class'],
    setup(props) {
        const accessToken = 'pk.eyJ1IjoicGhvaGVpc2VsIiwiYSI6ImNsM2ZxMHdjNjA3anIzZHQ1a2xxankydTIifQ.50dyxfxEbCNMjgxDwvoCmQ';
        const highlightLayer:any = ref({})

        let mapStyle: string;
        mapStyle = "";
        let highlightCountries: any;
        let highlightColor: string;

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt'
            highlightColor = '#6A72B6'
        } else {
            mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt' //cl3fujup7007014ndy0xpk1a1
            highlightColor = '#6A72B6'
        }

        const init = () => {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                mapStyle = 'styles/v1/phoheisel/clasjsacs005114ms57sod3yt'
                highlightColor = '#6A72B6'
            } else {
                mapStyle = 'styles/v1/phoheisel/clasjsacs005114ms57sod3yt'
                highlightColor = '#6A72B6'
            }

            highlightCountries = ["any"]
            if(props.usernameProfile.statsVisitedCountries){
                props.usernameProfile.statsVisitedCountries.forEach(country => {
                    highlightCountries.push(["in", country.toUpperCase(), ["get", "iso_3166_1"]]);     
                });
            }

            highlightLayer.value =  {
                id: 'highlighted-countries',
                source: {
                    type: 'vector',
                    url: 'mapbox://mapbox.country-boundaries-v1',
                },
                'source-layer': 'country_boundaries',
                type: 'fill',
                filter: [
                    "all",
                    highlightCountries,
                    [
                        "any",
                        ["==", "all", ["get", "worldview"]],
                        ["in", "US", ["get", "worldview"]]
                    ]
                ],
                paint: {
                    'fill-color': highlightColor,
                    'fill-opacity': 0.5,
                },
            }
        }

        init()

        watch(() => props.usernameProfile.statsVisitedCountries, () => {
            init()
        });

        return {
            accessToken,
            mapStyle,
            highlightLayer,
            props,
        }
    },
})
