// NOT USED ANYWHERE!!!

import { defineStore } from "pinia";
import {
  getCountry,
} from "./firebase";

interface State {
  globalCountries: any;
}

export const useDataStore = defineStore("dataStore", {
  // convert to a function
  state: (): State => ({
    globalCountries: [],
  }),
  actions: {
    async getGlobalCountry(code: string) {
      return new Promise((resolve) => {
        const foundCountry = this.globalCountries.find((x) => x.country === code)
        if(foundCountry) {
          console.log("found country in cache does it make any sense?")
          resolve(foundCountry);
        } else {
          console.log("add country in cache")
          getCountry(code).then((country) => {
            this.globalCountries.push(country)
            resolve(country);
          })
        }
      })
    },
  },
});