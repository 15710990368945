
import { defineComponent, ref, toRefs, watch } from 'vue';
import { useAppStore } from "@/store";
import FlagIcon from "@/components/FlagIcon.vue"
import { IonIcon } from '@ionic/vue';
import { pencilOutline, openOutline } from 'ionicons/icons';

export default defineComponent({
    name: "UserLocation",
    components: {
        FlagIcon,
        IonIcon
    },
    props: ['place', 'edit', 'gps', 'hideFullPlace', 'hideCountry', 'showLink', 'noLink'],
    setup(props) {
        const { openModalCountry } = useAppStore()
        const { place: reactPlace } = toRefs(props)
        const hidePlace = ref(props.hideFullPlace)
        const hideCntr = ref(props.hideCountry)

        const getCountry = (place) => {
            if(place.id.includes("country.")) return place.properties.short_code
            else {
                return place.context.find(x => x.id.includes("country.")).short_code
            }
        }

        watch(props, async () => {
            hidePlace.value = props.hideFullPlace
            hideCntr.value = props.hideCountry
        })
        
        return { 
            reactPlace,
            pencilOutline,
            openOutline,
            props,
            getCountry,
            hidePlace,
            hideCntr,
            openModalCountry
        }
    }
})
