
import { defineComponent, ref } from "vue";
import { useAppStore, useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { IonButton, IonCheckbox, IonContent, IonIcon, IonItem, IonLabel, IonModal, IonTextarea, IonTitle,  IonToolbar, toastController } from "@ionic/vue";
import { warningOutline } from 'ionicons/icons';
import { addBlock, addReport } from "@/store/firebase";

export default defineComponent({
  name: "ReportButton", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonCheckbox,
    IonLabel,
    IonTextarea
  },
  props: ["userId", "username", "content", "link"],
  setup(props) {
    const { profile, userBlocked } = storeToRefs(useAuthStore())
    const { reportModal } = storeToRefs(useAppStore())

    const options = ref()
    const modalRep = ref()

    const report:any = ref({})
    const reportSuccess= ref(false)

    const submit = async () => {
      if(report.value.content){
        //send report
        await addReport(profile.value ? profile.value.username : "", {
          username: props.username,
          content: props.content,
          link: props.link,
          text: report.value.contentText ? report.value.contentText : ""
        })
        reportSuccess.value = true
      } else {
        reportModal.value = false
      }
      if(report.value.block){
        //add user to blocklist
        if(!userBlocked.value.find(x => x.username === props.username)){
          await addBlock(profile.value.id, props.username)
            .then(async () => {
              const toast = await toastController
                .create({
                  message: props.username + ' was added to your blocked user list.',
                  duration: 2000,
                  position: 'top',
                  buttons: [
                    {
                      side: 'end',
                      text: 'ok',
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                      }
                    }
                  ]
                })
                toast.present();
            })
        } else {
          console.log("user was already blocked")
        }
      }
      report.value = {}
    }

    const closeModal = () => {
      reportModal.value = false
    }

    const beforePresent = () => {
      console.log("beforePresent")
    }

    const beforeDismiss = () => {
      console.log("beforeDismiss")
      report.value = {}
      reportSuccess.value = false
      reportModal.value = false
    }

    return {
      beforePresent,
      beforeDismiss,
      options,
      closeModal,
      reportModal,
      warningOutline,
      report,
      reportSuccess,
      modalRep,
      submit,
      userBlocked,
      profile
    }
  }
});
