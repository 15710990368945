
import { App } from '@capacitor/app';
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAuthStore, useAppStore } from "@/store";
import { consentAnalytics, dismissAnalytics, fbMessagingToken, initUserStatus, listenAppInfo, updateUserSettings } from "./store/firebase";
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform, IonLoading, IonContent, IonApp, IonRouterOutlet, IonIcon, IonMenu, IonList, IonItem, menuController, IonButton, IonButtons, IonMenuButton, IonToolbar, IonHeader, useIonRouter, IonBadge, IonTabBar, IonLabel, toastController, alertController, useBackButton } from "@ionic/vue";
import { logOutOutline, homeOutline, mapOutline, notificationsOutline, happyOutline, chatbubblesOutline, balloonOutline, earthOutline, navigateOutline, personCircleOutline, paperPlaneOutline, fileTrayStackedOutline, locationOutline, settingsOutline, ticketOutline, informationOutline, downloadOutline, bugOutline, searchOutline, serverOutline, peopleOutline, menuOutline, addOutline } from 'ionicons/icons';
import UserAvatar from "@/components/UserAvatar.vue"
import ConsentModal from "@/components/ConsentModal.vue"
import UpdateModal from "@/components/UpdateModal.vue"
import CountryModal from "@/components/Countries/CountryModal.vue"
import EditCountryModal from "@/components/Countries/EditCountryModal.vue"
import ConnnectionModal from "@/components/connections/ConnnectionModal.vue"
import EditContactModal from "@/components/connections/EditContactModal.vue"
import InvitesModal from "@/components/InvitesModal.vue"
import { useCookies } from 'vue3-cookies';
import UserLocation from "@/components/UserLocation.vue"
import ProfileMapboxStatic from "@/components/maps/ProfileMapboxStatic.vue"

export default defineComponent({
  name: 'App',
  components: {
    IonLoading,
    IonContent,
    IonApp,
    IonRouterOutlet,
    IonLabel,
    IonIcon,
    IonItem,
    IonMenu,
    IonList,
    UserAvatar,
    IonButton,
    IonButtons,
    IonMenuButton,
    IonToolbar,
    IonBadge,
    IonHeader,
    CountryModal,
    EditCountryModal,
    ConnnectionModal,
    EditContactModal,
    IonTabBar,
    ConsentModal,
    UpdateModal,
    InvitesModal,
    UserLocation,
    ProfileMapboxStatic
  },
  setup() {
    const { logoutUser, savePrivateFcmToken, removePrivateFcmToken, updateUserPresence } = useAuthStore();
    const { userCountries } = storeToRefs(useAuthStore())

    const { appIsLoading, getAlgoliaKey, getUserFacts, getFacts } = useAppStore();
    const ionRouter = useIonRouter();
    const router = useRouter();
    const { cookies } = useCookies();

    const { appInitialized, isLoggedIn, profile, userTrips, userFeed, fcmToken, adminRole, userSettings, needsAccountCreation, cannyLogin, userInvites, algoliaKeyNeedsUpdate } = storeToRefs(useAuthStore())
    const { appLoading, headerHidden, noHeader, lastExplorePath, cookieModal, cookieConsent, updateModal, inviteModal, seenOnboarding, requestingAlgoliaKey } = storeToRefs(useAppStore())

    const fact = ref("")
    let ownFacts:any = []
    const onLine = ref(navigator.onLine)
    const showBackOnline = ref(false)

    const invitesModal = ref(false)

    const doLogout = async () => {
      appIsLoading()
      seenOnboarding.value = false
      await removePrivateFcmToken();
      await updateUserPresence(false);
      await logoutUser();
      menuController.close("main-menu")
      menuController.close("user-menu")
      //window.location.assign('/login');
      ionRouter.push('/login')
      appLoading.value = false
    };

    const openLink = (url: string) => {
      menuController.close("main-menu")
      menuController.close("user-menu")
      ionRouter.push(url)
    }

    const updateOnlineStatus = (e) => {
      const { type } = e;
      if(!onLine.value && type === 'online') {
        showBackOnline.value = true
        setTimeout(()=>{
          showBackOnline.value = false
        }, 2000);
      }
      onLine.value = type === 'online'
    }

    const doRegisterNotification = async () => {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
      }
      await PushNotifications.register();
    }

    const listenNotification = () => {
      PushNotifications.addListener('registration', token => {
        console.info('Registration token: ', token.value);
        fcmToken.value = token.value
        savePrivateFcmToken(token.value)
      });
    
      PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
    
      PushNotifications.addListener('pushNotificationReceived', async (notification) => {
        console.log('Push notification received: ', notification);
        const toast = await toastController
          .create({
            message: notification.title,
            duration: 3000,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                  ionRouter.push(notification.link)
                }
              }
            ]
          })
          toast.present();
      });
    
      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed: ', notification);
        ionRouter.push(notification.notification.data.link)
      });
    }

    const initNotifications = () => {
      if(isPlatform("capacitor")){
        console.log("capacitor register notifications")
        listenNotification()
        doRegisterNotification()
      } else {
        // console.log("web messagin permission")
        // if(Notification){
        //   Notification.requestPermission()
        //     .then(() => {
        //       console.log('messagin permission')
        //       fbMessagingToken()
        //         .then((currentToken) => {
        //           if (currentToken) {
        //             // Send the token to your server and update the UI if necessary
        //             // ...
        //             console.log("new web token to be saved:"+currentToken)
        //             fcmToken.value = currentToken
        //             savePrivateFcmToken(currentToken)
        //           } else {
        //             // Show permission request UI
        //             console.log('No registration token available. Request permission to generate one.');
        //             // ...
        //           }
        //         }).catch((err) => {
        //           console.log('An error occurred while retrieving token. ', err);
        //           // ...
        //         });
        //     })
        //     .catch(() => {
        //       console.log('no messagin permission')
        //     })
        // }
      }  
    }

    const randomFact = () => {
      fact.value = ownFacts[Math.floor(Math.random() * Object.keys(ownFacts).length)]
    }

    const initFacts = () => {
      if(profile.value && userCountries.value){
        const userFacts = getUserFacts(profile.value, userCountries.value)
        ownFacts = userFacts.facts
        ownFacts.push(...getFacts())
        randomFact()
      }
    }

    const inviteAlert = async () => {
      invitesModal.value = true
      menuController.close("main-menu")
      menuController.close("user-menu")
    }

    listenAppInfo(info => {
      // app version appversion 1.2.1
      if(info.version > 1201 && !cookies.isKey('seenUpdate')) updateModal.value = true
      else updateModal.value = false
    })

    if(profile.value){
      initUserStatus(profile.value.id)
    }

    if(userSettings.value){
      if(userSettings.value.consentAnalytics == false || userSettings.value.consentAnalytics){
        cookieModal.value = false
      }
    }

    initFacts()

    useBackButton(-1, () => {
      console.log(router.currentRoute.value.path)
      // if(!ionRouter.canGoBack() && (router.currentRoute.value.path == '/home' || router.currentRoute.value.path.indexOf('/home'))) {
      if(!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });

    watch(profile, async () => {
      // console.log("watch profile")
      // console.log("isLoggedIn", isLoggedIn.value)
      if(isLoggedIn.value && profile.value && router.currentRoute.value.path.indexOf('/login') >= 0){
        console.log("redirect home")
        if(cannyLogin.value) {
          console.log("canny login redirect")
          const url = cannyLogin.value
          cannyLogin.value = false
          router.push(url);
        } else {
          router.push("/home");
        }
      }
      if(isLoggedIn.value && profile.value && !requestingAlgoliaKey.value){
        getAlgoliaKey(false).then(() => {
          algoliaKeyNeedsUpdate.value = false
        })
      }
      if(isLoggedIn.value && profile.value && !fcmToken.value){
        initNotifications()
      }
      if(isPlatform("capacitor")) cookieConsent.value = true
    })

    watch(algoliaKeyNeedsUpdate, async () => {
      if(algoliaKeyNeedsUpdate.value){
        getAlgoliaKey(algoliaKeyNeedsUpdate.value).then(() => {
          algoliaKeyNeedsUpdate.value = false
        })
      }
    })

    watch(userCountries, async () => {
      initFacts()
    })
    
    watch(userSettings, async () => {
      if(userSettings.value && (userSettings.value.consentAnalytics == false || userSettings.value.consentAnalytics)){
        cookieModal.value = false
      }
    })

    watch(cookieConsent, async () => {
      console.log("watch cookieConsent")
      console.log(cookieConsent.value)
      if(cookieConsent.value) {
        consentAnalytics()
        if(userSettings.value && profile.value){
          userSettings.value.consentAnalytics = true
          updateUserSettings(profile.value.id, userSettings.value)
        }
      }
      else {
        dismissAnalytics()
        if(userSettings.value && profile.value){
          userSettings.value.consentAnalytics = false
          updateUserSettings(profile.value.id, userSettings.value)
        }
      }
    })

    watch(needsAccountCreation, async () => {
      console.log("watch needsAccountCreation", needsAccountCreation.value)
      if(needsAccountCreation.value) router.push("/complete-account")
      else router.push("/")
    })

    watch(inviteModal, async () => {
      if(inviteModal.value) inviteAlert()
      inviteModal.value = false
    })

    onMounted(() => {
      window.addEventListener('online',  updateOnlineStatus)
      window.addEventListener('offline', updateOnlineStatus)
      if(userSettings.value && (userSettings.value.consentAnalytics == false || userSettings.value.consentAnalytics)){
        cookieModal.value = false
      } else if(isPlatform("ios") && isPlatform("capacitor")){
        cookieModal.value = true
      } else if(!cookies.isKey('seenCookiePopup')){
        cookieModal.value = true
      } else {
        if(cookies.isKey('analyticsConsent')) cookieConsent.value = true
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('online',  updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    })

    App.addListener('appUrlOpen', function (event) {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      const slug = event.url.split('.app').pop();
      console.log('appUrlOpen app vue', slug)

      // We only push to the route if there is a slug present
      if (slug) {
        router.push(slug);
      }
    });

    return {
      doLogout,
      logOutOutline,
      profile,
      openLink,
      notificationsOutline,
      homeOutline,
      fileTrayStackedOutline,
      settingsOutline,
      locationOutline,
      ticketOutline,
      informationOutline,
      downloadOutline,
      bugOutline,
      paperPlaneOutline,
      searchOutline,
      peopleOutline,
      menuOutline,
      serverOutline,
      chatbubblesOutline,
      earthOutline,
      navigateOutline,
      personCircleOutline,
      mapOutline,
      addOutline,
      happyOutline,
      appLoading,
      headerHidden,
      noHeader,
      isLoggedIn,
      router,
      userFeed,
      onLine,
      showBackOnline,
      inviteAlert,
      lastExplorePath,
      adminRole,
      cookieModal,
      isPlatform,
      appInitialized,
      invitesModal,
      userInvites,
      userTrips,
      randomFact,
      balloonOutline,
      fact
    }
  }
});
