import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.photo)
      ? (_openBlock(), _createBlock(_component_ion_avatar, {
          key: 0,
          class: "h-full w-full"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.photo,
              class: "h-full w-full",
              alt: 'manyways' + _ctx.username + 'user avatar'
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ion_icon, {
          key: 1,
          slot: "icon-only",
          icon: _ctx.personCircleOutline,
          class: "h-full w-full text-zinc-500"
        }, null, 8, ["icon"]))
  ]))
}