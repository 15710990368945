export default {
    settings: {
        adress: {
            name: "Adress",
            info: "Post adress",
            placeholder: "street, city, etc",
            field: "textarea",
            type: "",
            link: false,
            regex: false,
            svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>',
        },
        website: {
            name: "Website",
            info: "Url including https://",
            placeholder: "https://...",
            field: "input",
            type: "text",
            link: "",
            regex: false,
            svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg>',
        },
        couchsurfing: {
            name: "Couchsurfing",
            info: "",
            placeholder: "https://www.couchsurfing.com/people/...",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg id="svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 484.7"><style>.st0{fill:#d44a0c}</style><path id="path7" class="st0" d="M242 483.3c-17.2-3.7-35.8-18-43.3-33.4-6.3-12.8-6.8-16.5-6.7-44 .1-26.9.3-28.4 6.7-41.7 2.7-5.7 5.9-9.8 15.4-19.7 6.6-6.9 11.9-13.1 11.9-13.9s-1.9-3.1-4.2-5c-3.5-3-4.9-3.6-8.8-3.6-2.5 0-6.7.7-9.3 1.5-7.3 2.2-29.1 6.5-38.7 7.7-4.7.6-10.1 1.2-12.1 1.4-15.6 1.9-89.4 3.5-123.8 2.7L0 334.5v-24.1c0-28.1 0-28.1 7-26.3 6.4 1.7 105.1 1.7 130.5-.1 10.4-.7 21.5-1.7 24.5-2.2 3-.5 7.2-1.2 9.3-1.5s3.8-1.1 3.8-1.7-6.2-7.5-13.8-15.2c-31.5-32.2-46.1-56-54.1-88.6-2.2-8.8-3.4-28.3-3.1-49.5.1-8.3.2-15.7.2-16.5 0-1.7 1.4-9.8 3-17.5 4.4-21.2 15.7-40.8 33.6-58.2C159.4 15 181 4.7 207.6 1.5c56.2-6.9 95.5 10.4 126.3 55.4 9.1 13.3 13.7 22.5 17.6 35.5l3.6 12v33c0 36.8-.2 38.1-7.6 58-12.5 33.7-39.4 67.2-75.2 93.9-7.3 5.4-13.2 10.4-13.2 11.1s1.6 3 3.5 5c4.1 4.3 4 4.4 26.5-.5 26.3-5.7 30.1-6 82.5-6.7 27-.4 69.6-.3 94.8.1l45.8.8V323c0 19.8-.2 24-1.4 24.5-.8.3-12.4 0-25.8-.6-38.1-1.8-128.1-2.5-152.8-1.2-21.2 1.1-34.2 2.9-36.4 5.1-.8.8-.4 2.4 1.5 5.8 1.4 2.6 4.4 8.1 6.7 12.2 4.4 8.1 8.9 19.5 10.6 27.5.7 3.3.9 13.2.6 28.5-.6 28-.9 29-12.9 41.1-10.2 10.2-19 14.7-34.6 17.5-9.9 1.8-17.1 1.7-25.7-.1zm23.6-46c13.5-3.1 24.3-9.6 27.1-16.2 1.3-3.1 1.2-3.8-1.2-9-5.5-12.1-23-35-32.3-42.4-4-3.2-6.4-3-12.7 1-5.9 3.7-18.2 15.9-22.5 22.2-4.1 6.1-9.5 16.4-11.5 22-1 2.9-1 4.2 0 6.6 1.5 3.4 7.4 9.2 12.6 12.3 8.3 4.8 27.5 6.5 40.5 3.5zm-25.4-179.5c29.5-18.4 57.3-45.3 73.9-71.4 13.5-21.2 23.1-46.1 21.4-55.2-3-15.8-26.2-48.2-44-61.4-14.9-11-25.9-15.7-45-19-10-1.8-14.1-2-24.5-1.5-15.7.8-23.2 2.3-34.5 6.9-14.2 5.8-23.7 12.1-34.4 22.6-13 12.9-23.4 29.4-27 42.8-4 14.8 19.4 62.5 44.4 90.5 11.9 13.4 37.4 37.7 47.4 45.3 10.2 7.8 10.4 7.8 22.3.4z"/></svg>',
        },      
        email: {
            name: "Email",
            info: "",
            placeholder: "name@provider.de",
            field: "input",
            type: "email",
            link: "mailto:",
            regex: false,
            svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" /></svg>',
        },
        facebook: {
            name: "Facebook",
            info: "Facebook Link (User, Page, etc)",
            placeholder: "https://facebook.com/username",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="Facebook" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" fill="#1877f2"/><path d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z" fill="#fff"/></svg>',
        },
        linkedin: {
            name: "LinkedIn",
            info: "",
            placeholder: "https://www.linkedin.com/in/username..",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="LinkedIn" role="img" viewBox="0 0 512 512" fill="#fff"><rect width="512" height="512" rx="15%" fill="#0077b5"/><circle cx="142" cy="138" r="37"/><path stroke="#fff" stroke-width="66" d="M244 194v198M142 194v198"/><path d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32"/></svg>',
        },
        phone: {
            name: "Phone",
            info: "International phone number",
            placeholder: "+49 1234 5678",
            field: "input",
            type: "tel",
            link: "tel:",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>',
        },
        instagram: {
            name: "Instagram",
            info: "Instagram username",
            placeholder: "username or url",
            field: "input",
            type: "text",
            link: "https://instagram.com/",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-label="Instagram" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" id="b"/><use fill="url(#a)" xlink:href="#b"/><use fill="url(#c)" xlink:href="#b"/><radialGradient id="a" cx=".4" cy="1" r="1"><stop offset=".1" stop-color="#fd5"/><stop offset=".5" stop-color="#ff543e"/><stop offset="1" stop-color="#c837ab"/></radialGradient><linearGradient id="c" x2=".2" y2="1"><stop offset=".1" stop-color="#3771c8"/><stop offset=".5" stop-color="#60f" stop-opacity="0"/></linearGradient><g fill="none" stroke="#fff" stroke-width="30"><rect width="308" height="308" x="102" y="102" rx="81"/><circle cx="256" cy="256" r="72"/><circle cx="347" cy="165" r="6"/></g></svg>',
        },
        youtube: {
            name: "YouTube",
            info: "YouTube URL",
            placeholder: "https://youtube.com/...",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg height="auto" width="auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159 110"><path d="m154 17.5c-1.82-6.73-7.07-12-13.8-13.8-9.04-3.49-96.6-5.2-122 0.1-6.73 1.82-12 7.07-13.8 13.8-4.08 17.9-4.39 56.6 0.1 74.9 1.82 6.73 7.07 12 13.8 13.8 17.9 4.12 103 4.7 122 0 6.73-1.82 12-7.07 13.8-13.8 4.35-19.5 4.66-55.8-0.1-75z" fill="#f00"/><path d="m105 55-40.8-23.4v46.8z" fill="#fff"/></svg>',
        },
        telegram: {
            name: "Telegram",
            info: "Telegram username",
            placeholder: "username",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="Telegram" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" fill="#37aee2"/><path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144"/><path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34"/><path fill="#f6fbfe" d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"/></svg>',
        },
        tictoc: {
            name: "TikTok",
            info: "TikTok username",
            placeholder: "username",
            field: "input",
            type: "text",
            link: "https://www.tiktok.com/",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2859 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"/></svg>',
        },
        twitter: {
            name: "Twitter",
            info: "Twitter username",
            placeholder: "username",
            field: "input",
            type: "text",
            link: "https://twitter.com/",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="Twitter" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" fill="#1da1f2"/><path fill="#fff" d="M437 152a72 72 0 01-40 12a72 72 0 0032-40a72 72 0 01-45 17a72 72 0 00-122 65a200 200 0 01-145-74a72 72 0 0022 94a72 72 0 01-32-7a72 72 0 0056 69a72 72 0 01-32 1a72 72 0 0067 50a200 200 0 01-105 29a200 200 0 00309-179a200 200 0 0035-37"/></svg>',
        },
        whatsapp: {
            name: "WhatsApp",
            info: "International phone number",
            placeholder: "+49 1234 5678",
            field: "input",
            type: "tel",
            link: "tel:",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="WhatsApp" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" fill="#25d366"/><path fill="#25d366" stroke="#fff" stroke-width="26" d="M123 393l14-65a138 138 0 1150 47z"/><path fill="#fff" d="M308 273c-3-2-6-3-9 1l-12 16c-3 2-5 3-9 1-15-8-36-17-54-47-1-4 1-6 3-8l9-14c2-2 1-4 0-6l-12-29c-3-8-6-7-9-7h-8c-2 0-6 1-10 5-22 22-13 53 3 73 3 4 23 40 66 59 32 14 39 12 48 10 11-1 22-10 27-19 1-3 6-16 2-18"/></svg>',
        },
        wechat: {
            name: "WeChat",
            info: "WeChat unique username",
            placeholder: "user id",
            field: "input",
            type: "text",
            link: false,
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="WeChat" role="img" viewBox="0 0 512 512" fill="#fff"><rect width="512" height="512" rx="15%" fill="#00c70a"/><path d="M402 369c23-17 38 -42 38 -70c0-51 -50 -92 -111 -92s-110 41-110 92s49 92 110 92c13 0 25-2 36 -5c4-1 8 0 9 1l25 14c3 2 6 0 5-4l-6-22c0-3 2 -5 4 -6m-110-85a15 15 0 110-29a15 15 0 010 29m74 0a15 15 0 110-29a15 15 0 010 29"/><path d="m205 105c-73 0-132 50-132 111 0 33 17 63 45 83 3 2 5 5 4 10l-7 24c-1 5 3 7 6 6l30-17c3-2 7-3 11-2 26 8 48 6 51 6-24-84 59-132 123-128-10-52-65-93-131-93m-44 93a18 18 0 1 1 0-35 18 18 0 0 1 0 35m89 0a18 18 0 1 1 0-35 18 18 0 0 1 0 35"/></svg>',
        },
        xing: {
            name: "Xing",
            info: "",
            placeholder: "https://www.xing.com/profile/username/..",
            field: "input",
            type: "text",
            link: "",
            regex: "",
            svg: '<svg xmlns="http://www.w3.org/2000/svg" aria-label="XING" role="img" viewBox="0 0 512 512"><rect width="512" height="512" rx="15%" fill="#077"/><path d="M131 157l36 60v1l-54 94c-4 7-1 11 3 11h58c3 0 4 0 6-3l57-99v-2l-40-64-2-4-4-1h-56c-3 0-5 3-4 7zm217-74c-5 0-7 2-10 6L225 287v2l75 133c2 3 4 4 8 4h54c6 0 8-3 5-9l-74-128L406 90c2-4-1-7-5-7z" fill="#fff"/></svg>',
        },
    }
}



