import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.countrycode)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "inline-block rounded-sm shadow",
        src: '/assets/flags/'+_ctx.countrycode.toLowerCase()+'.png',
        alt: _ctx.countrycode.toLowerCase()+' flag icon'
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "?"))
}