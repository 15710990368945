
import { defineComponent, ref, watch } from "vue";
import { findUser } from "@/store/firebase";
import { useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { IonContent, IonHeader, IonIcon, IonInput, IonItem, IonList, IonModal, IonToolbar } from "@ionic/vue";
import { closeCircleOutline, searchOutline } from 'ionicons/icons';

export default defineComponent({
  name: "UserInput", 
  components: {
    IonHeader,
    IonModal,
    IonContent,
    IonToolbar,
    IonList,
    IonItem,
    IonIcon,
    IonInput
  },
  props: {
    appprofile: String,
    triggerOpen: Boolean,
    hidden: Boolean
  },
  emits: ["change"],
  setup(props, context) {
    const { userBlocked, userBlockedBy } = storeToRefs(useAuthStore())
    const gettingLocation = ref(false)
    const open = ref(false) 
    const input = ref("")
    const toolbar = ref()
    const content = ref()
    const inputel = ref()
    const modal = ref()
    const usersResult = ref<string[]>([])

    const updateUser = (username) => {
        if(username != ""){
            open.value = false    
            context.emit("change", username)
        } else {
            context.emit("change", "")
        }
    }

    const searchUser = async () => {
        usersResult.value = []
        findUser(input.value.toLowerCase())
            .then(users => {
                users.data.forEach(user => {
                    if(!userBlockedBy.value.find(x => x.id === user.id) && !userBlocked.value.find(x => x.id === user.id)) usersResult.value.push(user.username)
                })
                //usersResult.value = users
            })
            .catch(() => {
                //console.log("error no input?!")
            })
    }

    watch(props, async () => {
        open.value = props.triggerOpen
    })

    watch(open, async () => {
        input.value = ""
        usersResult.value = []
    })

    return {
      gettingLocation,
      open,
      toolbar,
      content,
      input,
      inputel,
      modal,
      usersResult,
      searchUser,
      updateUser,
      closeCircleOutline,
      searchOutline,
      props
    };
  },
});
