import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueVirtualScroller from 'vue-virtual-scroller';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Tailwind */
import './index.css'

/* Theme variables */
import './theme/variables.css';

import { createPinia } from "pinia";
import { useAuthStore } from "./store";
const pinia = createPinia();

const app = createApp(App)
  .use(IonicVue)
  .use(pinia);

// get the store
const authStore = useAuthStore();
authStore.initializeAuthListener()
app.use(router).mount("#app");


app.use(VueVirtualScroller);