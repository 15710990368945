
import { defineComponent, ref, toRaw, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore, useAuthStore } from "@/store";
import { addUserCountry, deleteUserCountry, updateUserCountry, updateUserCountryPrivate, addActivity } from "@/store/firebase";
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonToggle, IonToolbar, toastController } from "@ionic/vue";
import { sparklesOutline, pinOutline, documentTextOutline, pricetagsOutline, calendarClearOutline } from 'ionicons/icons';
import FlagIcon from "@/components/FlagIcon.vue"
import CountryYears from "@/components/Countries/CountryYears.vue"
import CountryPhoto from "@/components/Countries/CountryPhoto.vue"
import TagsInput from "@/components/TagsInput.vue"
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "EditCountryModal",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonHeader,
    IonToolbar,
    IonContent,
    IonToggle,
    IonTextarea,
    IonInput,
    IonButton,
    FlagIcon,
    CountryYears,
    CountryPhoto,
    TagsInput,
    IonFooter,
  },
  setup() {
    const { profile } = storeToRefs(useAuthStore())
    const { modalCountry, editModalCountry, appLoading } = storeToRefs(useAppStore())
    const router = useRouter()
    const route = useRoute()

    const modal = ref()
    const editCountry = ref()

    //if(modalCountry.value.code != editModalCountry.value.code) console.log("edit error!")

    const beforePresent = () => {
      editCountry.value = {}
      if(modalCountry.value.ownCountry) editCountry.value = JSON.parse(JSON.stringify(modalCountry.value.ownCountry))
    }

    const didPresent = () => {
      if(editCountry.value.visited || editCountry.value.planned) {
        setTimeout(() => {
          modal.value.$el.setCurrentBreakpoint(1)
        }, 100)
      } 
    }

    const addNewCountry = () => {
      const data = {
        createdOn: new Date(),
        updatedOn: new Date(),
        country: modalCountry.value.code,
        countryName: modalCountry.value.staticCountry.name,
        userId: profile.value.id,
        homeContacts: 0,
        metContacts: 0,
        planned: editCountry.value.planned ? editCountry.value.planned : false,
        visited: editCountry.value.visited ? editCountry.value.visited : false,
        photoUrl: editCountry.value.photoUrl ? editCountry.value.photoUrl : "",
        shortNote: editCountry.value.shortNote ? editCountry.value.shortNote : "",
        note: editCountry.value.note ? editCountry.value.note : "",
        exps: editCountry.value.exps ? editCountry.value.exps : [],
        years: editCountry.value.years ? editCountry.value.years : []
      }
      const notesToSave = editCountry.value.notes
      let notesAdded = false
      if(notesToSave) notesAdded = true
      appLoading.value = true
      addUserCountry(profile.value.id, profile.value.username, data).then(async () => {
        const activity = editCountry.value.visited ? "visited" : "planned"
        addActivity(profile.value.id, {
            type: "country",
            activity: activity,
            countryCode: modalCountry.value.code?.toLowerCase(),
            country: modalCountry.value.staticCountry.name
        })
        if(notesAdded) {
          updatePrivateNotes(notesToSave, true)
        } else {
          //message when no private changes:
          const toast = await toastController
          .create({
            message: 'New country has been saved.',
            duration: 2000,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          toast.present();
          appLoading.value = false
          editModalCountry.value = false
        } 
      })
    }

    const updatePrivateNotes = (notesToSave, countrySaved) => {
      let message = 'Private notes have been saved.'
      if(countrySaved) message = 'Country and private notes have been saved.'
      const countryPrivateToSave = {
        id: editModalCountry.value,
        notes: notesToSave
      }
      appLoading.value = true
      updateUserCountryPrivate(profile.value.id, countryPrivateToSave).then(async () => {
        const toast = await toastController
          .create({
            message: message,
            duration: 2000,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
        toast.present();
        appLoading.value = false
        editModalCountry.value = false
      })
    }

    const save = () => {
      const notesToSave = editCountry.value.notes
      let notesChanged = false
      if(modalCountry.value.ownCountry){
        //update country
        if(modalCountry.value.ownCountry.visited || modalCountry.value.ownCountry.planned) {
          //country been there already
          if(editCountry.value.notes) delete editCountry.value.notes; //delete for updating countries (notes will be saved on privat)
          if(modalCountry.value.ownCountry.notes != notesToSave) notesChanged = true
          if(!editCountry.value.visited && !editCountry.value.planned) {
            //should delete? (shoudn't happen as we disable toggle)
            if(editCountry.value.metContacts<1) {
              //delete country
              deleteUserCountry(profile.value.id, profile.value.username, editCountry.value.id).then(async () => {
                if(notesChanged){
                  updatePrivateNotes(notesToSave, true)
                } else {
                  //message when no private changes:
                  const toast = await toastController
                  .create({
                    message: 'Country has been removed.',
                    duration: 2000,
                    position: 'top',
                    buttons: [
                      {
                        side: 'end',
                        text: 'ok',
                        role: 'cancel',
                        handler: () => {
                          console.log('Cancel clicked');
                        }
                      }
                    ]
                  })
                  appLoading.value = false
                  toast.present();
                  editModalCountry.value = false
                  modalCountry.value.ownCountry = {}
                }
              })
            }
          } else {
            appLoading.value = true
            updateUserCountry(profile.value.id, profile.value.username, editCountry.value).then(async () => {
              if(notesChanged){
                updatePrivateNotes(notesToSave, true)
              } else {
                //message when no private changes:
                const toast = await toastController
                .create({
                  message: 'Country has been saved.',
                  duration: 2000,
                  position: 'top',
                  buttons: [
                    {
                      side: 'end',
                      text: 'ok',
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                      }
                    }
                  ]
                })
                appLoading.value = false
                toast.present();
                editModalCountry.value = false
                addActivity(profile.value.id, {
                    type: "country",
                    activity: 'update',
                    countryCode: modalCountry.value.code?.toLowerCase(),
                    country: modalCountry.value.staticCountry.name
                })
              }
            })
          }
        } else {
          if(editCountry.value.visited || editCountry.value.planned) {
            //2DO toast?!
            addNewCountry()
          }
          else if(modalCountry.value.ownCountry.notes != notesToSave) {
            updatePrivateNotes(notesToSave, false)
          }
        }
      } else {
        if(editCountry.value.visited || editCountry.value.planned) {
          //2DO toast?!
          addNewCountry()
        }
        else if(editCountry.value.notes) {
          updatePrivateNotes(notesToSave, false)
        }
      }
    }

    watch(editModalCountry, async () => {
      if(editModalCountry.value){
        await router.push(router.currentRoute.value.path+"#mdEditCountry"+editModalCountry.value)
      } else {
        if(route.hash.startsWith("#mdEditCountry")) {
          history.back()
        }
      }
    })   

    addEventListener('popstate', (event) => { 
      editModalCountry.value = false
    });

    return {
        modalCountry,
        sparklesOutline,
        pinOutline,
        editModalCountry,
        beforePresent,
        didPresent,
        editCountry,
        save,
        modal,
        documentTextOutline,
        pricetagsOutline,
        calendarClearOutline
    }
  }
});
