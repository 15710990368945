
import { defineComponent, onMounted, ref } from "vue";
import mapboxgl  from "mapbox-gl/dist/mapbox-gl.js";

export default defineComponent({
    name: "LandingMapbox",
    emits: ["pick"],
    setup(props, context) {
        const map = ref();
        mapboxgl.accessToken = 'pk.eyJ1IjoicGhvaGVpc2VsIiwiYSI6ImNsM2ZxMHdjNjA3anIzZHQ1a2xxankydTIifQ.50dyxfxEbCNMjgxDwvoCmQ';

        let mymap: any;
        let mapStyle: string;
        let highlightColor: string;

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt'
                    highlightColor = '#6A72B6'
        } else {
            mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt' //cl3fujup7007014ndy0xpk1a1
            highlightColor = '#6A72B6'
        }

        const initMap = async () => {

            mymap = new mapboxgl.Map({
                container: 'mapboxidpick',
                style: mapStyle,
                zoom: 0.1
            });

            const geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            })

            mymap.on('click', (e) => {
                console.log(e.lngLat.wrap());
                context.emit("pick", e.lngLat.wrap())
            });

            // Add geolocate control to the map.
            mymap.addControl(geolocate);

            mymap.on('load', () => {
                geolocate.trigger()
            })
        }

        onMounted(() => {           
            initMap()
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
                const scheme = event.matches ? "dark" : "light";
                if(scheme == "dark") {
                    mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt'
                    highlightColor = '#974063'
                }
                else {
                    mapStyle = 'mapbox://styles/phoheisel/clasjsacs005114ms57sod3yt'
                    highlightColor = '#6A72B6'
                } 
                initMap()
            });
        })
        return {
            map
        }
    },
})
