import { useAuthStore, useAppStore } from "@/store";
import { analytics } from "@/store/firebase";
import { logEvent } from "@firebase/analytics";
import { isPlatform } from "@ionic/vue";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const waitingForAppInitialized = (to: any, from: any, next: any) => {
  setTimeout(() => {
    authCheck(to, from, next)
  }, 500)
}

/**
 * 
 * @param to 
 * @param from 
 * @param next 
 */
const authCheck = (to: any, from: any, next: any) => {
  const store = useAuthStore();
  if(!store.appInitialized){
    waitingForAppInitialized(to, from, next)
  } else {
    if(store.isLoggedIn && !store.needsAccountCreation) {
      if(to.name === "login") {
        next({ name: "home" });
      } else {
        next();
      }
    } else if(store.needsAccountCreation) {
      console.log("router: needaccountcreation")
      next({name: "complete account"})
    } else {
      if (to.name === "login") {
        next();
      } else {
        if(isPlatform("capacitor")){
          next({ name: "login" });
        }
        else {
          next({ name: "landing" });
        }
      }
    }
  }
};


const startLoading = () => {
  const appStore = useAppStore()
  appStore.appIsLoading()
}

const stopLoading = () => {
  const appStore = useAppStore()
  appStore.appNotLoading()
}

const showHeader = () => {
  const appStore = useAppStore()
  appStore.showHeader()
}

const removeHeader = () => {
  const appStore = useAppStore()
  appStore.removeHeader()
}

const addBottom = () => {
  const appStore = useAppStore()
  appStore.addBottom()
}

const removeBottom = () => {
  const appStore = useAppStore()
  appStore.removeBottom()
}

const addHeader = () => {
  const appStore = useAppStore()
  appStore.addHeader()
}

const routes: Array<RouteRecordRaw> = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
    beforeEnter: authCheck,
    meta: {
      hiddenHeader: true,
      title: "Login beta"
    },
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("@/views/AdminPage.vue"),
    beforeEnter: authCheck,
    children: [
      {
        path: 'activities',
            component: () => import("@/views/Admin/AdminActivitiesPage.vue"),
            meta: {
              title: "Admin",
              hiddenHeader: false,
            }
      },
      {
        path: 'users',
            component: () => import("@/views/Admin/AdminUsersPage.vue"),
            meta: {
              title: "Admin",
              hiddenHeader: false,
            }
      },
      {
        path: 'old',
        component: () => import("@/views/Admin/OldAdminPage.vue"),
        meta: {
          title: "Admin",
          hiddenHeader: false,
        }
      },
    ],
  },
  { 
    path: '/landing', redirect: { name: 'landing' }
  },
  {
    name: "landing",
    path: "/",
    component: () => import("@/views/LandingPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "manyways to reveal your travel experience"
    },
  },
  {
    name: "lp",
    path: "/lp",
    component: () => import("@/views/LandingPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "manyways to reveal your travel experience"
    },
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("@/views/LegalPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Legal & Privacy"
    },
  },
  {
    name: "about",
    path: "/about",
    component: () => import("@/views/AboutPage.vue"),
    beforeEnter: authCheck,
    meta: {
      hiddenHeader: true,
      title: "About"
    },
  },
  {
    name: "complete account",
    path: "/complete-account",
    component: () => import("@/views/CompleteAccountPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Complete Account"
    },
  },
  {
    name: "create account",
    path: "/create-account",
    component: () => import("@/views/CreateAccountPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Create Account"
    },
  },
  {
    name: "email action",
    path: "/email-action",
    component: () => import("@/views/EmailActionPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Email Action"
    },
  },
  {
    name: "home",
    path: "/home",
    component: () => import("@/views/HomePage.vue"),
    beforeEnter: authCheck,
    meta: {
      title: "Welcome"
    }
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: () => import("@/views/OnboardingPage.vue"),
    beforeEnter: authCheck,
    meta: {
      hiddenHeader: true,
      title: "Get started"
    }
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("@/views/ChatPage.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "report",
    path: "/report",
    component: () => import("@/views/ReportPage.vue"),
    beforeEnter: authCheck,
    meta: {
      hiddenHeader: true,
      title: "Report problem"
    }
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("@/views/ContactPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Contact"
    }
  },
  {
    name: "canny",
    path: "/canny",
    component: () => import("@/views/CannyPage.vue"),
    meta: {
      hiddenHeader: true,
      title: "Canny login"
    }
  },
  {
    name: "feed",
    path: "/feed",
    component: () => import("@/views/FeedPage.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "export",
    path: "/export",
    component: () => import("@/views/ExportPage.vue"),
    beforeEnter: authCheck,
    meta: {
      title: "Export",
      hiddenHeader: true
    }
  },
  {
    name: "profile countries",
    path: "/user/:username/countries",
    meta: {
      hiddenHeader: true,
      title: "Traveled countries",
      titleDyn: "username"
    },
    component: () => import("@/views/Profile/ProfileCountriesPage.vue"),
  },
  {
    name: "profile trips",
    path: "/user/:username/trips",
    meta: {
      hiddenHeader: true,
      title: "Trips",
      titleDyn: "username"
    },
    component: () => import("@/views/Profile/ProfileTripsPage.vue"),
  },
  {
    name: "profile trip",
    path: "/user/:username/trips/:trip",
    meta: {
      hiddenHeader: true,
      title: "Trip",
      titleDyn: "username"
    },
    component: () => import("@/views/Profile/ProfileTripPage.vue"),
  },
  {
    name: "profile edit trip",
    path: "/user/:username/trips/edit/:trip",
    component: () => import("@/views/Profile/ProfileEditTripPage.vue"),
    beforeEnter: authCheck,
    meta: {
      hiddenHeader: true,
      title: "Edit Trip",
      titleDyn: "username"
    }
  },
  {
    name: "profile",
    path: "/user/:username",
    meta: {
      hiddenHeader: true,
      title: "Travel profile",
      titleDyn: "username"
    },
    component: () => import("@/views/ProfilePage.vue"),
  },
  {
    name: "map",
    path: "/map",
    component: () => import("@/views/MapPage.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("@/views/ExplorePage.vue"),
    beforeEnter: authCheck
  },
  {
    name: "connections",
    path: "/connections",
    component: () => import("@/views/ConnectionsPage.vue"),
    beforeEnter: authCheck,
    children: [
      {
        path: 'contacts',
            component: () => import("@/views/Connections/ContactsConnectionsPage.vue"),
            meta: {
              title: "Contacts",
              hiddenHeader: true,
            }
      },
      {
        path: 'following',
        component: () => import("@/views/Connections/FollowingConnectionsPage.vue"),
        meta: {
          title: "Following",
          hiddenHeader: true,
        }
      },
      {
        path: 'explore',
        component: () => import("@/views/Explore/ExplorePeoplePage.vue"),
        meta: {
          title: "Explore",
          hiddenHeader: true,
        }
      }
    ],
  },
  {
    name: "settings",
    path: "/settings",
    meta: {
      hiddenHeader: true
    },
    component: () => import("@/views/SettingsPage.vue"),
    beforeEnter: authCheck,
    children: [
      {
        path: 'profile',
        meta: {
          hiddenHeader: true
        },
            component: () => import("@/views/Settings/ProfileSettingsPage.vue"),
      },
      {
        path: 'interview',
        meta: {
          hiddenHeader: true
        },
        component: () => import("@/views/Settings/InterviewSettingsPage.vue"),
      },
      {
        path: 'app',
        meta: {
          hiddenHeader: true
        },
        component: () => import("@/views/Settings/AppSettingsPage.vue"),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      hiddenHeader: true,
      title: "404 not found"
    },
    component: () => import("@/views/404Page.vue"),
  }
  // { path: "*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.afterEach((to, from) => {
  stopLoading()
})

router.beforeEach((to:any, from: any, next: any) => {
  if(to.name == "landing" && isPlatform("capacitor")){
    next("/login"+to.hash)
  }
  else if(to.name == "lp" && !isPlatform("capacitor")) next("/")
  else {
    if(to.meta.hiddenHeader) removeHeader()
    else addHeader()
    if(from.meta.forceBottomBar) removeBottom()
    if(to.meta.forceBottomBar) addBottom()
    showHeader()
    startLoading()
    const dynTitle = to.meta.titleDyn ? ': '+to.params[to.meta.titleDyn] : ''
    document.title = typeof to.meta.title == "string" ? to.meta.title + dynTitle + ' - manyways.app' : 'manyways.app'
    if(analytics) {
      //analytics.setCurrentScreen(document.title)
      logEvent(analytics, "screen_view")
    }
    next();  
  }

})

export default router;