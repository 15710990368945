
import { defineComponent, ref } from "vue";
import { useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { isPlatform, IonContent, IonModal, IonTitle,  IonToolbar, IonItem, IonLabel, IonIcon, alertController } from "@ionic/vue";
import { shareOutline, ticketOutline } from 'ionicons/icons';
import { Share } from "@capacitor/share";

export default defineComponent({
  name: "InvitesModal", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonIcon
  },
  props: ["open"],
  emits: ["close"],
  setup(props, context) {
    const { userInvites } = storeToRefs(useAuthStore())
    const showShareLink = ref(false)

    const beforePresent = () => {
      // console.log("before present")
    }

    const beforeDismiss = () => {
      context.emit("close", false)
    }

    const shareInvite = async (code) => {
      const title = 'Check out manyways.app'
      const text = 'I\'d like to connect with you on manyways. Here is your personal invite code "'+code+'" to join for free. Can\'t wait to see your there ❤️'
      const url = 'https://manyways.app/#nvtcd'+code
      if(isPlatform("capacitor")){
        await Share.share({
          title: title,
          text: text,
          url: url,
          dialogTitle: 'Share manyways profile',
        });
      } else {
        if(showShareLink.value == true) {
            showShareLink.value = false
        } else {
          if(navigator.share) {
              navigator.share({
                  title: title,
                  text: text,
                  url: url,
              })
                  .then(() => console.log('Successful share'))
                  .catch(async (error) => {
                    console.log('Error sharing', error)
                    //showShareLink.value = true
                    const alert = await alertController.create({
                      header: 'Share',
                      message: 'Copy to share:',
                      inputs: [
                          {
                              type: 'textarea',
                              value: title+'\n'+text+'\n'+url
                          }
                      ],
                      buttons: [
                        {
                            text: 'OK',
                            role: 'confirm',
                        }
                      ]
                    });
                    await alert.present();
                  })
          } else {
              //showShareLink.value = true
              const alert = await alertController.create({
                header: 'Share',
                message: 'Copy to share:',
                inputs: [
                    {
                        type: 'textarea',
                        value: title+'\n'+url
                    }
                ],
                buttons: [
                  {
                      text: 'OK',
                      role: 'confirm',
                  }
                ]
              });
              await alert.present();
          }                      
        }
      }
    }

    return {
      beforeDismiss,
      beforePresent,
      isPlatform,
      userInvites,
      shareOutline,
      ticketOutline,
      shareInvite
    }
  }
});
