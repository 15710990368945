import { defineStore } from 'pinia'
import { getAllExps, getCountryUsers, getUser, getAllCountries, getUserCountries, getUserId, getInterviewQuestions } from './firebase'
import { allCountries } from "@/store";
import { functions } from "@/store/firebase";
import { httpsCallable } from 'firebase/functions';

interface State {
    noHeader: boolean,
    headerHidden: boolean,
    forceBottomBar: boolean,
    appLoading: boolean,
    cookieConsent: boolean,
    cookieModal: boolean,
    updateModal: boolean,
    inviteModal: boolean,
    reportModal: boolean,
    fullscreenInnerRouter: boolean,
    modalCountry: {
        code?: string;
        user?: object;
        globalCountry?: any;
        ownCountry?: any;
        userCountry?: object;
        staticCountry?: any;
    },
    modalConnection: any,
    lastExplorePath: any,
    modalTrip: any,
    editModalCountry: any,
    editModalContact: any,
    editModalContactFocus: string,
    cachedUsernames: any,
    cachedProfiles: any,
    cachedGlobalCountries: any,
    cachedUserCountries: any,
    cachedExps: any,
    cachedAvatars: any,
    cachedCountryUsers: any,
    cachedUserInterviews: any,
    cachedAlgoliaKey: any,
    requestingAlgoliaKey: any,
    draftTrip: any,
    seenOnboarding: boolean
}

export const useAppStore = defineStore("appStore", {
    state: (): State => ({
        noHeader: false,
        headerHidden: false,
        forceBottomBar: false,
        appLoading: false,
        cookieConsent: false,
        cookieModal: false,
        updateModal: false,
        inviteModal: false,
        reportModal: false,
        fullscreenInnerRouter: false,
        modalCountry: {},
        modalConnection: {},
        lastExplorePath: "",
        modalTrip: false,
        editModalCountry: false,
        editModalContact: false,
        editModalContactFocus: "",
        cachedUsernames: [],
        cachedProfiles: [],
        cachedGlobalCountries: {},
        cachedUserCountries: [],
        cachedExps: {},
        cachedAvatars: {},
        cachedCountryUsers: {},
        cachedUserInterviews: {},
        cachedAlgoliaKey: false,
        requestingAlgoliaKey: false,
        draftTrip: false,
        seenOnboarding: false
    }),
    getters: {
        isHeaderHidden: (state) => state.headerHidden,
        isAppLoading: (state) => state.appLoading
    },
    actions: {
        checkFollowStatus(userFollowing: Array<any>, username: any) {
            let followStatus = 'none'
            if(userFollowing && userFollowing.find(x => x.username === username)){
                followStatus = 'pending'
                if(userFollowing && userFollowing.find(x => x.username === username).confirmed) {
                    followStatus = 'confirmed'
                }
            }
            return followStatus
        },
        checkFollowerStatus(userFollower: Array<any>, username: any) {
            let followerStatus = false
            if(userFollower && userFollower.find(x => x.username === username)){
                followerStatus = true
            }
            return followerStatus
        },
        cacheAvatar(username: string, photo: string) {
            this.cachedAvatars[username] = photo
        },
        cachedAvatar(username: string){
            return this.cachedAvatars[username]
        },
        appIsLoading() {
            this.appLoading = true
        },
        appNotLoading() {
            this.appLoading = false
        },
        setFullscreenInnerRouter(set: boolean) {
            this.fullscreenInnerRouter = set
        },
        removeHeader() {
            this.noHeader = true
        },
        addHeader() {
            this.noHeader = false
        },
        hideHeader() {
            this.headerHidden = true
        },
        showHeader() {
            this.headerHidden = false
        },
        removeBottom() {
            this.forceBottomBar = false
        },
        addBottom() {
            this.forceBottomBar = true
        }, 
        openModalConnection(connection: object) {
            this.modalConnection = connection
        },
        openModalCountry(country: object) {
            this.modalCountry = country
        },
        openEditModalCountry(country: any) {
            this.editModalCountry = country
        },
        openModalTrip(trip: object) {
            this.modalTrip = trip
        },
        openEditModalContact(contact: any, focus: string) {
            this.editModalContact = contact
            this.editModalContactFocus = focus
        },
        addModalGlobalCountry(global: any) {
            this.modalCountry.globalCountry = global
        },
        addModalUserCountry(country: any) {
            this.modalCountry.userCountry = country
        },
        getFacts() {
            const facts = <any>[]
            facts.push("Surfing is one of the oldest sports on Earth. Stone carvings from 5000 years ago!")
            facts.push("english is the most spoken language with 1.5 billion speakers")
            facts.push("197 United Nations Member States")
            facts.push("The longest flight is from Singapore to New Jersey")
            facts.push("Australia is wider than the moon")
            facts.push("Vatican City is the tiniest country")
            facts.push("Saudi Arabia has no rivers.")
            facts.push("The UK has the world’s longest country name.")
            facts.push("The German word “fernweh” means “far sickness,” or an intense urge to travel")
            facts.push("99% of Libya is desert")
            facts.push("I can't wait to <a href='mailto:philip@manyways.app'>get your feedback</a> to this!")
            facts.push("The Eiffel Tower is a daily place of work for 600 Parisians")
            facts.push("Continents shift at about the same rate as your fingernails grow. Nailed it!")
            facts.push("Rice was used in the construction of the Great Wall of China")
            facts.push("Polynesian island of Niue has official currency embossed Pokemon!")
            facts.push("Bhutan is the only country to measure Gross National Happiness")
            facts.push("Canada has the most lakes in the world")
            facts.push("Bolivia holds title as the flattest country in the world.")
            facts.push("Russia is our worlds largest country with borders to 16 states.")
            facts.push("San Marino is the oldest country, originally formed in 301 AD")
            facts.push("France’s longest land border is to Brazil, thanks French Guiana")
            //
            facts.push("Glaciers and ice sheets hold about 69 percent of the world's freshwater.")
            facts.push("The fastest gust of wind ever recorded on Earth was 253 miles per hour.")
            facts.push("The best place in the world to see rainbows is in Hawaii.")
            facts.push("There are fossilized plants in Greenland under 1.4 km of ice.")
            facts.push("Whale songs can be used to map out the ocean floor.")
            facts.push("North Korea and Cuba are the only places you can't buy Coca-Cola.")
            facts.push("The entire world's population could fit inside Los Angeles.")
            facts.push("More people visit France than any other country.")
            facts.push("The Canary Islands are named after dogs, not birds.")
            facts.push("U.S. is the only country that doesn't use the metric system.")
            facts.push("Approximately four babies are born every second.")
            facts.push("Japan is the world's most earthquake-prone country.")
            facts.push("There are around 4 quadrillion quadrillion bacteria on Earth.")
            facts.push("People currently alive represent about 7% of total people ever lived.")
            facts.push("Muhammad is thought to be the most popular name in the world.")
            facts.push("Nicaragua and Dominica, the only countries use purple in their flags.")
            facts.push("Africa and Asia are home to nearly 90 percent of the world's rural population.")
            facts.push("A record-breaking 92 countries competed in the 2018 Winter Olympics.")
            facts.push("South Sudan is the youngest country in the world.")
            facts.push("More than 52% of the world's population is under 30 years old.")
            facts.push("People 60 years and older make up 12.3% of the global population.")
            facts.push("There are more than 24 time zones around the world.")
            facts.push("It's estimated that Sweden has more islands than any other country.")
            facts.push("There are 43 countries that still have a royal family.")
            facts.push("All giant pandas in zoos around the world are on loan from China.")
            facts.push("Canada has 9% of the world's forests.")
            facts.push("The red-billed quelea is the most common bird on Earth.")
            facts.push("There are 41 countries that recognize sign language as an official language")
            facts.push("The global adult literacy rate is around 86%")
            facts.push("All the ants on Earth weigh about as much as all the humans")
            facts.push("New Zealanders have more pets per household than any other country")
            facts.push("The tiny nation of Nauru is the smallest island country in the world.")
            facts.push("There are 63 million pairs of chopsticks manufactured in China every year.")
            facts.push("No one knows exactly how many islands indonesia has")
            facts.push("Australia is the only continent with no active volcanoes")
            facts.push("China is spanning over 5 time zones but only using Beijing time.")
            facts.push("New Zealand was the first country to give woman the right to vote.")
            facts.push("Russias boreal/taiga forest produces most of worlds oxygen.")
            facts.push("Kiribati is only country to fall within all four hemispheres")
            facts.push("One third of Netherlands is below sea level")
            facts.push("Afghanistan and Cambodia are the only featuring a building in their flags.")
            facts.push("The flags of Monaco & Indonesia are identical except in dimension")
            facts.push("The flags of Romania & Chad differ only in the shade of blue they use.")
            facts.push("The flag of Libya from 1977 to 2011 was the only plain flag in the world")
            facts.push("Nepal is the only country with a non-quadrilateral flag")
            facts.push("Africa has 54 countries")
            facts.push("Antarctica is the largest desert in the world")
            facts.push("The official animal of Scotland is the unicorn!")
            facts.push("Africa is the home for largest living land animals, the African elephant.")
            //facts.push("")
            return facts
        },
        getUserFacts(user: any, countCountries: any) {
            const plannedCountries = <any>[]
            const visitedCountries = <any>[]
            let countMet = 0 //how many contacts
            let countMets = 0 //how many countries where contacts met
            let countHomes = 0 //how many countries with contacts home
            let countCountryMets = <any>{}
            let countCountryHomes = <any>{}
            let countContinents = <any>{} // countries regions
            const countryPhotos = <any>[]
            const profileFacts = <any>[]
            const facts = <any>[]
            for (const country of countCountries) {
                if(country.planned) plannedCountries.push(country)
                if(country.visited) visitedCountries.push(country)
                countMet += country.metContacts > 0 ? country.metContacts : 0
                countMets += country.metContacts > 0 ? 1 : 0
                countHomes += country.homeContacts > 0 ? 1 : 0
                !countCountryMets[country.id.toLowerCase()] ? countCountryMets[country.id.toLowerCase()] = country.metContacts : 0
                !countCountryHomes[country.id.toLowerCase()] ? countCountryHomes[country.id.toLowerCase()] = country.homeContacts : 0
                if(country.photoUrl && country.photoUrl != "") countryPhotos.push(country)           
            }
            visitedCountries.forEach(country => {
                const countryDetails = allCountries.find((x) => x["alpha2Code"].toLowerCase() == country.id.toLowerCase())
                if(countryDetails) {
                    !countContinents[countryDetails.region] ? countContinents[countryDetails.region] = 1 : countContinents[countryDetails.region] += 1
                }
            })
            countContinents = Object.fromEntries(
                Object.entries(countContinents).sort(([,a]:any,[,b]:any) => b-a)
            )
            countCountryMets = Object.fromEntries(
                Object.entries(countCountryMets).sort(([,a]:any,[,b]:any) => b-a)
            )
            countCountryHomes = Object.fromEntries(
                Object.entries(countCountryHomes).sort(([,a]:any,[,b]:any) => b-a)
            )
            //setUserFacts:
            if(countContinents){
                profileFacts.push(user.username+" been in "+Object.keys(countContinents).length+" continents")
                facts.push("you've been in "+Object.keys(countContinents).length+" continents")
            }
            if(user.exps){
                user.exps.sort((a, b) => {
                    return b.count - a.count
                })
                if(user.exps.length>0) {
                    profileFacts.push(user.username+" most experience in countries is "+user.exps[0].name+"")
                    facts.push("your most experience in countries is "+user.exps[0].name+"")
                }
            }
            if(visitedCountries) {
                profileFacts.push(user.username+" seen "+Math.round((100 * Object.keys(visitedCountries).length) / 193)+"% of all countries")
                facts.push("you've seen "+Math.round((100 * Object.keys(visitedCountries).length) / 193)+"% of all countries")
            } 
            if(Object.keys(countCountryMets).length > 0) {
                const mostMets = Object.keys(countCountryMets)
                const mostMetCountry = allCountries.find((x) => x["alpha2Code"].toLowerCase() == mostMets[0])
                if(mostMetCountry) facts.push("you met most contacts in "+mostMetCountry.name)
            }
            if(Object.keys(countCountryHomes)) {
                const mostHomes = Object.keys(countCountryHomes)
                const mostHomeCountry = allCountries.find((x) => x["alpha2Code"].toLowerCase() == mostHomes[0])
                if(mostHomeCountry) facts.push("most of you're contacts are in "+mostHomeCountry.name)
            }
            return {
                profileFacts: profileFacts,
                facts: facts
            }
        },
        async getStoredUserId(username) {
            return new Promise<any>((resolve, reject) => {
                const cached = this.cachedUsernames.find((x) => x.username === username)
                if(cached) resolve(cached.user)
                else {
                    getUserId(username)
                    .then(user => {
                        this.cachedUsernames.push({username: username, id: user.id, user: user})
                        resolve(user);
                    })
                    .catch(error => {
                        reject(error);
                    })
                }
            })
        },
        async storeUser(user) {
            const cached = this.cachedProfiles.find((x) => x.id === user.id)
            if(!cached){
                this.cachedProfiles.push({id: user.id, user: user, timestamp: Date.now()})
            } else if(cached && cached.timestamp < Date.now()-300000) {
                //update cache:
                this.cachedProfiles = this.cachedProfiles.filter((x) => x.id !== user.id)
                this.cachedProfiles.push({id: user.id, user: user, timestamp: Date.now()})
            }
        },
        async getStoredUser(userid) {
            return new Promise<any>((resolve, reject) => {
                const cached = this.cachedProfiles.find((x) => x.id === userid)
                if(cached) {
                    if(cached.timestamp > Date.now()-300000) {
                        //console.log("from cache")
                        resolve(cached.user)
                        return
                    }
                    //console.log("cleanup")
                    this.cachedProfiles = this.cachedProfiles.filter((x) => x.id !== userid)
                }
                getUser(userid)
                    .then(user => {
                        //console.log("firebase profile")
                        this.cachedProfiles.push({id: userid, user: user, timestamp: Date.now()})
                        resolve(user);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        async getStoredGlobalCountries() {
            return new Promise<any>((resolve, reject) => {
                const cached = this.cachedGlobalCountries
                if(cached) {
                    if(cached.timestamp > Date.now()-300000) {
                        //console.log("global countries from cache")
                        resolve(cached.countries)
                        return
                    }
                    //console.log("cleanup user countries")
                    this.cachedGlobalCountries = {}
                }
                getAllCountries()
                    .then(countries => {
                        //console.log("firebase global countries")
                        this.cachedGlobalCountries = { countries: countries, timestamp: Date.now() }
                        resolve(countries);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        async getStoredUserCountries(userid) {
            return new Promise<any>((resolve, reject) => {
                const cached = this.cachedUserCountries.find((x) => x.id === userid)
                if(cached) {
                    if(cached.timestamp > Date.now()-300000) {
                        //console.log("user countries from cache")
                        resolve(cached.countries)
                        return
                    }
                    //console.log("cleanup user countries")
                    this.cachedUserCountries = this.cachedUserCountries.filter((x) => x.id !== userid)
                }
                getUserCountries(userid)
                    .then(countries => {
                        //console.log("firebase user countries")
                        this.cachedUserCountries.push({id: userid, countries: countries, timestamp: Date.now()})
                        resolve(countries);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        async getStoredExps() {
            return new Promise<any>((resolve, reject) => {
                if(this.cachedExps) {
                    if(this.cachedExps.timestamp > Date.now()-300000) {
                        //console.log("exps from cache")
                        resolve(this.cachedExps.exps)
                        return
                    }
                    //console.log("cleanup cached exps")
                    this.cachedExps = false
                }
                getAllExps()
                    .then(exps => {
                        //console.log("firebase exps")
                        this.cachedExps = {exps: exps.data, timestamp: Date.now()}
                        resolve(this.cachedExps.exps);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        getStoredCountryUsers(country: string, photos: boolean){
            return new Promise<any>((resolve, reject) => {
                if(this.cachedCountryUsers[country]) {
                    if(this.cachedCountryUsers[country].timestamp > Date.now()-300000) {
                        //console.log("country users from cache")
                        resolve(this.cachedCountryUsers[country].users)
                        return
                    }
                    //cleanup:
                    //console.log("cleanup cached exps")
                    this.cachedCountryUsers[country] = false
                }
               //console.log("photos? "+photos)
                getCountryUsers(country, photos)
                    .then(users => {
                        //console.log("firebase users")
                        if(users){
                            this.cachedCountryUsers[country] = {users: users.data, timestamp: Date.now()}
                            users.data.forEach(user => {
                                this.storeUser(user)                           
                            });
                            //console.log(this.cachedCountryUsers)
                            resolve(this.cachedCountryUsers[country].users);
                        } else {
                            reject("error firebase")
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        async getStoredUserInterviews() {
            return new Promise<any>((resolve, reject) => {
                if(this.cachedUserInterviews) {
                    if(this.cachedUserInterviews.timestamp > Date.now()-300000) {
                        //console.log("exps from cache")
                        resolve(this.cachedUserInterviews.questions)
                        return
                    }
                    //console.log("cleanup cached exps")
                    this.cachedUserInterviews = false
                }
                getInterviewQuestions()
                    .then(questions => {
                        //console.log("firebase exps")
                        this.cachedUserInterviews = {questions: questions.data, timestamp: Date.now()}
                        resolve(this.cachedUserInterviews.questions);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        async getAlgoliaKey(force:boolean) {
            return new Promise<any>((resolve, reject) => {
                if(!force && this.cachedAlgoliaKey && this.cachedAlgoliaKey.valid > new Date(Date.now()).getTime()/1000){
                    // console.log("cached key valid:", this.cachedAlgoliaKey.valid)
                    resolve(this.cachedAlgoliaKey.publicKey)
                } else {
                    this.requestingAlgoliaKey = true
                    console.log("requestingAlgoliaKey", this.requestingAlgoliaKey)
                    const algoliaKey = httpsCallable(functions, 'algoliaKey');
                    algoliaKey()
                        .then((key) => {
                            console.log("new key")
                            this.requestingAlgoliaKey = false
                            this.cachedAlgoliaKey = key.data
                            resolve(this.cachedAlgoliaKey.publicKey)
                        })
                        .catch((error) => {
                            reject(error)
                        })   
                }       
            })
        },
        async getAlgoliaAdminKey() {
            return new Promise<any>((resolve, reject) => {
                console.log("requestingAlgoliaAdminKey")
                const algoliaAdminKey = httpsCallable(functions, 'algoliaAdminKey');
                algoliaAdminKey()
                    .then((key) => {
                        console.log("new admin key", key)
                        resolve(key.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })   
     
            })
        }
    }
})