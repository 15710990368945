import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "block w-full" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-400 text-sm"
}
const _hoisted_3 = { class: "block w-full" }
const _hoisted_4 = {
  key: 1,
  class: "w-full pb-4 z-50"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ion_select, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
        onIonChange: _cache[1] || (_cache[1] = () => { _ctx.addField() }),
        label: _ctx.label,
        labelPlacement: "stacked",
        interface: "action-sheet",
        placeholder: "add link/option"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_select_option, { value: "" }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!_ctx.linkonly || (option.link==='' || option.link!=false))
                ? (_openBlock(), _createBlock(_component_ion_select_option, {
                    key: option.name,
                    value: key
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["modelValue", "label"])
    ]),
    (_ctx.editContactOptions && _ctx.options)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editContactOptions, (option, key) => {
            return (_openBlock(), _createBlock(_component_ion_item, { key: key }, {
              default: _withCtx(() => [
                _createVNode(_component_IonRow, { class: "w-full" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IonCol, {
                      size: "1",
                      class: "ion-align-self-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          innerHTML: _ctx.options[option.key].svg
                        }, null, 8, _hoisted_5)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_IonCol, { size: "10" }, {
                      default: _withCtx(() => [
                        (_ctx.options[option.key].field=='textarea')
                          ? (_openBlock(), _createBlock(_component_ion_textarea, {
                              key: 0,
                              modelValue: option.value,
                              "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                              label: _ctx.options[option.key].name,
                              labelPlacement: "stacked",
                              rows: "3",
                              placeholder: _ctx.options[option.key].info,
                              onIonBlur: _ctx.emitOptions
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "onIonBlur"]))
                          : _createCommentVNode("", true),
                        (_ctx.options[option.key].field=='input')
                          ? (_openBlock(), _createBlock(_component_ion_input, {
                              key: 1,
                              modelValue: option.value,
                              "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                              label: _ctx.options[option.key].name,
                              labelPlacement: "stacked",
                              placeholder: _ctx.options[option.key].placeholder,
                              onIonBlur: _ctx.emitOptions
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "onIonBlur"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_IonCol, {
                      size: "1",
                      class: "ion-align-self-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.removeField(key)),
                          class: "h-5 w-5 block mt-5"
                        }, [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.closeCircleOutline,
                            class: "h-full w-full"
                          }, null, 8, ["icon"])
                        ], 8, _hoisted_6)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}