import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b8f7831"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-slate-500 dark:text-slate-300"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "shadow-md flex justify-left items-center py-1 pb-0 px-5 pr-3 rounded-3xl bg-white dark:bg-zinc-900 w-full mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "w-full py-3 flex space-x-2 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = () => { _ctx.open=true; })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (year) => {
        return (_openBlock(), _createElementBlock("div", {
          key: year,
          class: "bg-zinc-200 dark:bg-zinc-700 rounded-lg p-1 px-2 text-sm"
        }, _toDisplayString(year), 1))
      }), 128)),
      (!_ctx.selectedOptions || _ctx.selectedOptions.length<1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "no years"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ion_modal, { "is-open": _ctx.open }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, { ref: "toolbar" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { class: "w-full" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open=false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.chevronBackOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createVNode(_TransitionGroup, {
                    name: "tagWrapper",
                    tag: "div",
                    class: "w-full p-2 pt-0 flex gap-2 flex-wrap"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (year) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: year,
                          class: "bg-zinc-200 dark:bg-zinc-700 rounded-lg p-1 px-2 text-sm"
                        }, [
                          _createTextVNode(_toDisplayString(year) + " ", 1),
                          _createElementVNode("span", {
                            onClick: () => _ctx.remove(year),
                            class: "text-red-500 cursor-pointer"
                          }, "X", 8, _hoisted_2)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_IonIcon, {
                        slot: "icon-only",
                        icon: _ctx.searchOutline,
                        class: "text-xl mr-5"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_input, {
                        modelValue: _ctx.input,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input) = $event)),
                        onIonInput: _cache[3] || (_cache[3] = (i) => { _ctx.input = i.detail.value; _ctx.onFilter() }),
                        "aria-label": "filter years",
                        ref: "inputelhome",
                        placeholder: "filter years"
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { ref: "content" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.found, (year) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: year,
                    onClick: () => _ctx.add(year)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(year), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}