import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = {
  key: 0,
  class: "float-right h-5 w-5"
}
const _hoisted_3 = { class: "w-6 inline-block" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm block pt-1"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "w-6 inline-block"
}
const _hoisted_7 = {
  key: 1,
  class: "inline-block"
}
const _hoisted_8 = {
  key: 2,
  class: "text-sm inline-block"
}
const _hoisted_9 = {
  key: 3,
  class: "text-sm inline-block"
}
const _hoisted_10 = {
  key: 0,
  class: "text-sm block pt-1"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "w-6 inline-block" }
const _hoisted_13 = {
  key: 0,
  class: "inline-block"
}
const _hoisted_14 = {
  key: 1,
  class: "text-sm inline-block"
}
const _hoisted_15 = {
  key: 2,
  class: "text-sm inline-block"
}
const _hoisted_16 = {
  key: 1,
  class: "text-sm w-full"
}
const _hoisted_17 = {
  key: 0,
  class: "float-right h-5 w-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_FlagIcon = _resolveComponent("FlagIcon")!

  return (_ctx.place && Object.keys(_ctx.place).length>0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.props.edit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.pencilOutline,
                class: "h-full w-full"
              }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.place.id)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_FlagIcon, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.props.noLink ? _ctx.openModalCountry({code: _ctx.getCountry(_ctx.place).toUpperCase()}) : null)),
                  countrycode: _ctx.getCountry(_ctx.place)
                }, null, 8, ["countrycode"])
              ]),
              _createTextVNode("  "),
              _createElementVNode("span", null, _toDisplayString(_ctx.place.place_name.split(",")[0]) + " ", 1),
              _createVNode(_Transition, { name: "slide" }, {
                default: _withCtx(() => [
                  (!_ctx.hidePlace)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.place.place_name.split(",").splice(1).toString()) + " ", 1),
                        (_ctx.props.showLink)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: 'http://maps.google.com/maps?q=loc:'+_ctx.place.geometry.coordinates[1]+'+'+_ctx.place.geometry.coordinates[0],
                              target: "_blank",
                              class: "text-mwhighlight ml-1"
                            }, [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.openOutline
                              }, null, 8, ["icon"])
                            ], 8, _hoisted_5))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : (_ctx.place.properties)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.place.properties.countrycode)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_FlagIcon, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.props.noLink ? _ctx.openModalCountry({code: _ctx.place.properties.countrycode}) : null)),
                        countrycode: _ctx.place.properties.countrycode.toUpperCase()
                      }, null, 8, ["countrycode"])
                    ]))
                  : _createCommentVNode("", true),
                _createTextVNode("  "),
                (_ctx.place.properties.name)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.place.properties.name) + " ", 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, "No name " + _toDisplayString(_ctx.place.properties.type) + " ", 1)),
                (_ctx.place.properties.type == 'city' || _ctx.place.properties.type == 'county')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.place.properties.type) + " ", 1))
                  : _createCommentVNode("", true),
                _createVNode(_Transition, { name: "slide" }, {
                  default: _withCtx(() => [
                    (!_ctx.hidePlace)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                          _createTextVNode(_toDisplayString(_ctx.place.properties.city ? _ctx.place.properties.city + ', ' : '') + _toDisplayString(_ctx.place.properties.county ? _ctx.place.properties.county + ', ' : '') + _toDisplayString(_ctx.place.properties.country ? _ctx.place.properties.country : '') + " ", 1),
                          (_ctx.props.showLink)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: 'http://maps.google.com/maps?q=loc:'+_ctx.place.geometry.coordinates[1]+'+'+_ctx.place.geometry.coordinates[0],
                                target: "_blank",
                                class: "text-mwhighlight ml-1"
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.openOutline
                                }, null, 8, ["icon"])
                              ], 8, _hoisted_11))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_component_FlagIcon, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.props.noLink ? _ctx.openModalCountry({code: _ctx.place.countrycode}) : null)),
                    countrycode: _ctx.place.countrycode.toUpperCase()
                  }, null, 8, ["countrycode"])
                ]),
                _createTextVNode("  "),
                (_ctx.place.name)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.place.name) + " ", 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_14, "No name " + _toDisplayString(_ctx.place.type) + " ", 1)),
                (_ctx.place.type == 'city' || _ctx.place.type == 'county')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.place.type) + " ", 1))
                  : _createCommentVNode("", true)
              ], 64))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
        (_ctx.props.edit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.pencilOutline,
                class: "h-full w-full"
              }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true),
        _createTextVNode(" no location chosen ")
      ]))
}