
import { defineComponent, onUpdated, ref, watch } from "vue";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonToolbar } from "@ionic/vue";
import { chevronBackOutline, searchOutline } from 'ionicons/icons';
import { useAppStore } from "@/store";

export default defineComponent({
  name: "TagsInput", 
  components: {
    IonHeader,
    IonModal,
    IonContent,
    IonList,
    IonItem,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon
  },
  props: {
    selected: Array,
    closeOnSelect: Boolean,
    placeholder: String
  },
  emits: ["change"],
  setup(props, context) {
    const { getStoredExps } = useAppStore()

    const exps = ref<any[]>([])
    const initalSelected = props.selected ? JSON.parse(JSON.stringify(props.selected)) : []
    const selectedOptions = ref(initalSelected)
    const open = ref(false)
    const input = ref("")
    const found = ref<any[]>([])
    const toolbar = ref()
    const content = ref()
    const inputelhome = ref()

    const onFilter = () => {
      if(input.value=="") {
        found.value = exps.value
      }
      else {
        found.value = exps.value.filter((exp: any) => exp.name.toLowerCase().includes(input.value.toLowerCase()))
      }
    }

    const adjustOffset = () => {
      if(content.value) {
        content.value.$el.setAttribute("style", "--offset-bottom:-"+toolbar.value.$el.offsetHeight+"px")
      } else {
        setTimeout(adjustOffset, 500)
      }
    }

    const emitSelected = () => {
      context.emit("change", selectedOptions.value)
    }

    const add = (exp: string) => {
      selectedOptions.value.push(exp)
      if(input.value == "") onFilter()
      else input.value = ""
      emitSelected()
      focusInput()
      if(props.closeOnSelect) open.value = false
    }

    const remove = (exp: string) => {
      selectedOptions.value = selectedOptions.value.filter((cntry: any) => cntry !== exp)
      if(input.value == "") onFilter()
      else input.value = ""
      emitSelected()
      focusInput()
    }

    const focusInput = () => {
      if(open.value) inputelhome.value.$el.setFocus()
    }

    watch(open, async () => {
      setTimeout(() => {
        focusInput()
      }, 1500)
    })

    watch(props, async () => {
      const updateInitial = props.selected ? JSON.parse(JSON.stringify(props.selected)) : []
      selectedOptions.value = updateInitial
    })

    getStoredExps().then((result) => {
      exps.value = result
      onFilter()
    })

    onUpdated(() => {
      adjustOffset()
    })

    return {
      selectedOptions,
      open,
      input,
      onFilter,
      found,
      exps,
      add,
      remove,
      toolbar,
      content,
      inputelhome,
      chevronBackOutline,
      searchOutline,
      props
    };
  },
});
