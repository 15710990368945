import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-2"
}
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_3 = { class: "shadow-md flex justify-left items-center py-1 pb-0 px-5 pr-3 rounded-3xl bg-white dark:bg-zinc-900 w-full mb-2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_IonModal = _resolveComponent("IonModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.props.hidden)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.appprofile != '' && _ctx.appprofile != undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, _toDisplayString(_ctx.appprofile), 1),
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = () => { _ctx.updateUser('') }),
                  class: "w-5 h-5 ml-2 mt-1 inline-block"
                }, [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.closeCircleOutline,
                    class: "h-full w-full"
                  }, null, 8, ["icon"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = () => { _ctx.open = true }),
                class: "text-zinc-500"
              }, " no connected user "))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_IonModal, {
      "is-open": _ctx.open,
      breakpoints: [0, 0.8],
      initialBreakpoint: 0.8,
      onDidDismiss: _cache[4] || (_cache[4] = () => { _ctx.open = false }),
      ref: "modal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, {
              ref: "toolbar",
              class: "pt-7 p-2"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_IonIcon, {
                    slot: "icon-only",
                    icon: _ctx.searchOutline,
                    class: "text-xl mr-5"
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input) = $event)),
                    onIonInput: _cache[3] || (_cache[3] = (i) => { _ctx.input = i.detail.value; _ctx.searchUser() }),
                    "aria-label": "find user",
                    ref: "inputel",
                    placeholder: "find user",
                    "clear-input": ""
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersResult, (user) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: user,
                    onClick: () => _ctx.updateUser(user)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(user), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "breakpoints", "initialBreakpoint"])
  ], 64))
}