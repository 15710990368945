
import { useAppStore, useAuthStore } from "@/store";
import { getCountry } from "@/store/firebase";
import { IonIcon, IonSpinner } from "@ionic/vue";
import { pricetagsOutline } from 'ionicons/icons';
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";
import UserAvatar from "../UserAvatar.vue";

export default defineComponent({
    name: "GlobalCountryModal",
    components: { 
      UserAvatar,
      IonSpinner,
      IonIcon
    },
    emits: ["userClick"],
    setup(props, context) {
      const { userBlocked, userBlockedBy } = storeToRefs(useAuthStore())
      const { addModalGlobalCountry, getStoredCountryUsers } = useAppStore();
      const { modalCountry } = storeToRefs(useAppStore());

      const countryUsers:any = ref([])
      const countryUsersLoading = ref(false)

      const changeUser = (user) => {
        context.emit("userClick", user)
      }

      if((!modalCountry.value.globalCountry || modalCountry.value.globalCountry == undefined) && typeof modalCountry.value.code === "string") {
        countryUsersLoading.value = true
        getCountry(modalCountry.value.code).then((globalCountry) => {
          addModalGlobalCountry(globalCountry);
          if(globalCountry && globalCountry.country){
            getStoredCountryUsers(globalCountry.country, globalCountry.photoUrlCount > 0).then(users => {
              countryUsers.value = []
              users.forEach(user => {
                if(!userBlockedBy.value.find(x => x.id === user.id) && !userBlocked.value.find(x => x.id === user.id)) countryUsers.value.push(user)
              })
              //countryUsers.value = users
              countryUsersLoading.value = false
            });
          } else {
            countryUsersLoading.value = false
          }
        })
      } else {
        if(!countryUsers.value || countryUsers.value.length < 1){
          countryUsersLoading.value = true
          getStoredCountryUsers(modalCountry.value.globalCountry.country, modalCountry.value.globalCountry.photoUrlCount > 0).then(users => {
            countryUsers.value = []
            users.forEach(user => {
              if(!userBlockedBy.value.find(x => x.id === user.id) && !userBlocked.value.find(x => x.id === user.id)) countryUsers.value.push(user)
            })
            //countryUsers.value = users
            countryUsersLoading.value = false
          });
        } else {
          countryUsersLoading.value = false
        }
      }

      return {
          modalCountry,
          countryUsers,
          countryUsersLoading,
          pricetagsOutline,
          changeUser
      };
    }
});
