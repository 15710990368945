
import { defineComponent } from "vue"

export default defineComponent({
    name: 'MarkdownWrap',
    components: {
    },
    props: ["source"],
    setup() {
        const parseMarkdown = (markdownText) => {
            const htmlText = replaceURLs(strip(markdownText))
                .replace(/^### (.*$)/gim, '<h3>$1</h3>')
                .replace(/^## (.*$)/gim, '<h2>$1</h2>')
                .replace(/^# (.*$)/gim, '<h1>$1</h1>')                
                .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
                .replace(/\*(.*)\*/gim, '<i>$1</i>')
                .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2' target='_blank'>$1</a>")
                .replace(/\n$/gim, '<br />')
            return htmlText.trim()
        }

        const replaceURLs = (message) => {
            if(!message) return;
            //message = message.toLowerCase()
            var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/gim;
            return message.replace(urlRegex, function (url) {
                var hyperlink = url;
                if (!hyperlink.match('^https?://')) {
                    hyperlink = 'http://' + hyperlink;
                }
                return '[' + hyperlink + '](' + url + ')'
            });
        }

        const strip = (html) => {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }

        return {
            parseMarkdown,
            replaceURLs,
            strip,
        }
    }
})
