
import { defineComponent, ref, watch } from 'vue';
import { storeToRefs } from 'pinia'
import { IonAvatar, IonIcon } from "@ionic/vue";
import { useAppStore, useAuthStore } from '@/store';
import { personCircleOutline } from 'ionicons/icons';

export default defineComponent({
    name: "UserAvatar",
    components: {
        IonAvatar,
        IonIcon
    },
    props: ['username', 'photoUrl'],
    setup(props) {
        const { profile } = storeToRefs(useAuthStore())
        const { cacheAvatar, cachedAvatar, getStoredUserId, getStoredUser } = useAppStore()
        const photo = ref(props.photoUrl)

        const init = () => {
            if(!photo.value) {
                if(props.username === profile.value?.username){
                    photo.value = profile.value.photoUrl
                } else {
                    const fromCache = cachedAvatar(props.username)
                    if(fromCache) photo.value = fromCache
                    else {
                        //check for users photo
                        photo.value = false
                        getStoredUserId(props.username)
                            .then(usernameData => {
                                usernameData.uid
                                getStoredUser(usernameData.uid)
                                    .then(user => {
                                        photo.value = user.photoUrl ? user.photoUrl : false
                                    })
                                    .catch(err => {
                                        console.log("avatar get user", err)
                                    })
                            })
                            .catch(() => {
                                photo.value = false
                            })
                    }
                }
            } else {
                if(photo.value != "" && props.username && props.username != ""){
                    cacheAvatar(props.username, photo)
                }
            }
        }

        init()

        watch(props, async () => {
            photo.value = props.photoUrl
            init()
        })
        
        return {
            photo,
            personCircleOutline
        }
    }
})
