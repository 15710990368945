
import { defineComponent, ref, watch } from "vue";
import { useAppStore, useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { alertController, IonButton, IonCol, IonContent, IonIcon, IonModal,  IonRow,  IonTitle,  IonToolbar } from "@ionic/vue";
import { chatbubbles, lockClosedOutline, flagOutline, pinOutline, homeOutline, pencilOutline, personCircleOutline, ticketOutline, searchOutline, earthOutline, navigateOutline, chatbubblesOutline, cameraOutline, sparklesOutline, locationOutline } from 'ionicons/icons';
import MarkdownWrap from "@/components/MarkdownWrap.vue"
import UserAvatar from "@/components/UserAvatar.vue"
import ContactOptions from "@/scripts/ContactOptions.js"
import { useRoute, useRouter } from "vue-router";
import UserLocation from "@/components/UserLocation.vue"
import FlagIcon from "@/components/FlagIcon.vue"

export default defineComponent({
  name: "ConnectionModal", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonRow,
    IonCol,
    IonIcon,
    MarkdownWrap,
    UserAvatar,
    UserLocation,
    FlagIcon
  },
  setup() {
    const { modalConnection, inviteModal } = storeToRefs(useAppStore())
    const { getStoredUserId, getStoredUser, checkFollowStatus, openEditModalContact, appNotLoading } = useAppStore()
    const { follow, unfollow, requestFollow, cancelRequestFollow } = useAuthStore()
    const { userContacts, userFollowing, profile } = storeToRefs(useAuthStore())
    const route = useRoute()
    const router = useRouter()

    const view = ref('global')
    const options = ref()
    const modalConn = ref()

    const userId = ref()
    const usernameProfile:any = ref(false)
    const publicProfile = ref(false)
    const followStatus = ref('none') //none, pending, confirmed
    const followLoading = ref(false)

    const contact = ref(false)

    const closeModal = () => {
      modalConn.value.$el.setCurrentBreakpoint(0)
    }

    const followUser = () => {
      if(!publicProfile.value){
        requestFollow(modalConnection.value.username, userId.value).then(() => {
          //2DO: toast?!
          console.log("follow reqeust: "+modalConnection.value.username)
        })
      } else {
        follow(usernameProfile.value).then(() => {
          //2DO: toast?!
          if(usernameProfile.value.public == true) followLoading.value = true
          console.log("follow: "+usernameProfile.value.username)
        })
      }
    }

    const getCountry = (place) => {
      if(!place) return false
      else if(place.id){
        if(place.id.includes("country.")) return place.properties.short_code
        else {
          return place.context.find(x => x.id.includes("country.")).short_code
        }
      } else {
        return place.countrycode
      }
    }

    const cancelPendingFollowUser = () => {
      cancelRequestFollow(userId.value).then(() => {
        console.log("cancel follow request")
      })
    }

    const unfollowUser = () => {
      unfollow(usernameProfile.value).then(() => {
        console.log("unfollow")
      })
    }

    const initUsernameProfile = (username) => {
      getStoredUserId(username).then(usernameData => {
        userId.value = usernameData.uid
        getStoredUser(usernameData.uid)
          .then(user => {
            publicProfile.value = true
            usernameProfile.value = user
            followStatus.value = checkFollowStatus(userFollowing.value, username)
          })
          .catch(() => {
            publicProfile.value = false
            followStatus.value = checkFollowStatus(userFollowing.value, username)
          })
      })
    }

    const beforePresent = () => {
      options.value = ContactOptions.settings

      if(modalConnection.value.contact) {
        contact.value = modalConnection.value.contact
        if(modalConnection.value.contact.appprofile){
          initUsernameProfile(modalConnection.value.contact.appprofile)
        } else {
          usernameProfile.value = false
        }
      } else if(modalConnection.value.username) {
        initUsernameProfile(modalConnection.value.username)
        //check for contact:
        contact.value = userContacts.value.find((x) => x.appprofile == modalConnection.value.username)
        modalConnection.value.contact = contact.value
      }
      
      // if(route.hash.startsWith("#cnct")){
      //   userToConnect.value = route.hash.replace('#cnct', '')
      //   console.log('user to connectx: '+userToConnect.value)
      //   const contactToLink = modalConnection.value.contact
      //   contactToLink.appprofile = userToConnect
      //   openEditModalContact(contactToLink, 'existing')
      //   modalConnection.value = {}
      // }
      if(modalConnection.value.userToConnect){
        openEditModalContact(modalConnection.value.contact, 'existing')
        //modalConnection.value = {}
     }
    }

    const beforeDismiss = () => {
      modalConnection.value = {}
      contact.value = false
      usernameProfile.value = false
      followStatus.value = 'none'
    }

    watch(userFollowing, async () => {
      const username = modalConnection.value.username ? modalConnection.value.username : modalConnection.value.contact ? modalConnection.value.contact.appprofile : undefined
      followStatus.value = checkFollowStatus(userFollowing.value, username)
    })

    watch(userContacts, async () => {
      if(Object.keys(modalConnection).length > 0 && modalConnection.value.username) modalConnection.value.contact = userContacts.value.find((x) => x.appprofile == modalConnection.value.username)
    })

    watch(modalConnection, async () => {
      if(modalConnection.value.contact) {
        contact.value = modalConnection.value.contact
        if(modalConnection.value.contact.appprofile){
          initUsernameProfile(modalConnection.value.contact.appprofile)
        } else {
          usernameProfile.value = false
        }
      }
      //
      if(modalConnection.value.contact || modalConnection.value.username){
        if(!route.hash.startsWith("#mdEditContact")) await router.push(router.currentRoute.value.path+"#mdContact")
      } else {
        if(route.hash.startsWith("#mdContact")) {
          history.back()
        }
      }
    }, { deep: true })

    addEventListener('popstate', (event) => { 
      if(!route.hash.startsWith("#mdContact")) modalConnection.value = false
    });

    return {
        modalConnection,
        modalConn,
        view,
        beforePresent,
        beforeDismiss,
        options,
        chatbubbles,
        personCircleOutline,
        ticketOutline,
        searchOutline,
        earthOutline,
        navigateOutline,
        chatbubblesOutline,
        pencilOutline,
        cameraOutline,
        lockClosedOutline,
        sparklesOutline,
        usernameProfile,
        locationOutline,
        pinOutline,
        homeOutline,
        flagOutline,
        contact,
        followStatus,
        cancelPendingFollowUser,
        followUser,
        unfollowUser,
        openEditModalContact,
        publicProfile,
        closeModal,
        followLoading,
        inviteModal,
        getCountry,
        appNotLoading,
        profile
    }
  }
});
