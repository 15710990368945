
import { defineComponent, onUpdated, ref } from "vue";
import ContactOptions from "@/scripts/ContactOptions.js";
import { IonCol, IonIcon, IonInput, IonItem, IonRow, IonSelect, IonSelectOption, IonTextarea } from "@ionic/vue";
import { closeCircleOutline } from 'ionicons/icons';

export default defineComponent({
    name: "ContactInput", 
    components: {
        IonSelect,
        IonSelectOption,
        IonItem,
        IonRow,
        IonCol,
        IonInput,
        IonTextarea,
        IonIcon
    },
    props: {
        max: Number,
        usersContactOptions: Array,
        linkonly: Boolean,
        label: String
    },
    emits: ["change"],
    setup(props, context) {
        const selected = ref("")
        const options = ref({})
        const result = ref({})
        const editContactOptions = ref<object[]>([])
        const error = ref<any>(false)

        const max = props.max ? props.max : 20

        const emitOptions = () => {
            context.emit("change", editContactOptions.value)
        }

        const addField = () => {
            if(selected.value != ""){
                if (editContactOptions.value.length < max) {
                    editContactOptions.value.unshift({key: selected.value, value: ""})
                    selected.value = ""
                } else {
                    selected.value = ""
                    error.value = "You can maximum add "+max+" options"
                    setTimeout(() => {
                        error.value = false
                    }, 3000)
                }
            }
        }
        
        const removeField = (key) => {
            editContactOptions.value = editContactOptions.value.slice(0, key).concat(editContactOptions.value.slice(key + 1, editContactOptions.value.length))
        }

        if(props.usersContactOptions && props.usersContactOptions.length>0){
            props.usersContactOptions.forEach((value) => {              
                editContactOptions.value.push(JSON.parse(JSON.stringify(value)))
            })
        }
        options.value = ContactOptions.settings

        onUpdated(() => {
            emitOptions()
        })

        return {
            selected,
            options,
            result,
            editContactOptions,
            error,
            addField,
            removeField,
            closeCircleOutline,
            emitOptions
        }
    },
});
