import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { slot: "end" }
const _hoisted_4 = { class: "flex h-9" }
const _hoisted_5 = {
  key: 0,
  class: "pb-64 ion-padding pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlagIcon = _resolveComponent("FlagIcon")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_IonModal = _resolveComponent("IonModal")!

  return (_openBlock(), _createBlock(_component_IonModal, {
    "is-open": Object.keys(_ctx.modalCountry).length > 0,
    breakpoints: [0, 0.9],
    initialBreakpoint: 0.9,
    onWillPresent: _cache[5] || (_cache[5] = () => { _ctx.beforePresent() }),
    onDidDismiss: _cache[6] || (_cache[6] = () => { _ctx.modalCountry = {} }),
    ref: "modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        ref: "toolbar",
        class: "p-5 pb-0"
      }, {
        default: _withCtx(() => [
          (_ctx.modalCountry.staticCountry && _ctx.modalCountry.staticCountry.name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _createVNode(_component_FlagIcon, {
                  class: "w-7 mr-2",
                  countrycode: _ctx.modalCountry.code
                }, null, 8, ["countrycode"]),
                _createTextVNode(" " + _toDisplayString(_ctx.modalCountry.staticCountry.name), 1)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.modalCountry.code) + " not found", 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.view = 'global')),
                class: _normalizeClass(["h-8 w-8 block shadow transition-all ease-in-out duration-300 dark:bg-zinc-700 dark:text-mwmain-light rounded-full ml-2 text-center pt-1 text-2xl", {'bg-mwmain-light dark:bg-mwmain h-9 w-9 leading-9' : _ctx.view == 'global'}])
              }, [
                _createVNode(_component_IonIcon, {
                  slot: "icon-only",
                  icon: _ctx.earthOutline
                }, null, 8, ["icon"])
              ], 2),
              (_ctx.modalCountry.user && (!_ctx.profile || _ctx.modalCountry.user != _ctx.profile.username))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.view = 'user')),
                    class: _normalizeClass(["h-8 w-8 block shadow transition-all duration-300 dark:bg-zinc-700 dark:text-mwmain-light rounded-full ml-2 p-1", [{'bg-mwmain-light dark:bg-mwmain h-9 w-9' : _ctx.view == 'user'}, {'animate-bounce': _ctx.userChanged}]])
                  }, [
                    _createVNode(_component_UserAvatar, {
                      username: _ctx.modalCountry.user,
                      class: "h-full w-full"
                    }, null, 8, ["username"])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.modalCountry.ownCountry)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.view = 'own')),
                    class: _normalizeClass(["h-8 w-8 block shadow transition-all duration-300 dark:bg-zinc-700 dark:text-mwmain-light rounded-full ml-2 p-1", {'bg-mwmain-light dark:bg-mwmain h-9 w-9' : _ctx.view == 'own'}])
                  }, [
                    _createVNode(_component_UserAvatar, {
                      username: _ctx.profile.username,
                      photoUrl: _ctx.profile.photoUrl,
                      class: "h-full w-full"
                    }, null, 8, ["username", "photoUrl"])
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    onClick: _cache[3] || (_cache[3] = () => { _ctx.profile ? _ctx.openEditModalCountry(_ctx.modalCountry.code) : _ctx.guestAddCountryAlert() }),
                    class: "h-8 w-8 block shadow transition-all duration-300 dark:bg-zinc-700 dark:text-mwmain-light rounded-full ml-2 text-center pt-1 leading-9 text-2xl"
                  }, [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.addCircleOutline
                    }, null, 8, ["icon"])
                  ]))
            ])
          ])
        ]),
        _: 1
      }, 512),
      _createVNode(_component_ion_content, { ref: "scrollcontainer" }, {
        default: _withCtx(() => [
          (_ctx.modalCountry.staticCountry && _ctx.modalCountry.staticCountry.name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.view.charAt(0).toUpperCase() + _ctx.view.slice(1)+'CountryModal'), {
                  onUserClick: _cache[4] || (_cache[4] = (user) => { _ctx.changeUser(user) })
                }, null, 32))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["is-open", "breakpoints", "initialBreakpoint"]))
}