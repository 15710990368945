import { User as fbUser, updateProfile } from "firebase/auth";
import { defineStore } from "pinia";
import allCountries from "../../public/assets/all.json";
import {
  fbAuthStateListener,
  fbCreateAccount,
  fbSignIn,
  fbResendVerification,
  fbSignOut,
  listenUser,
  listenUserCountries,
  listenUserPrivateCountries,
  listenUserExport,
  listenUserExportExports,
  listenUserFeed,
  listenUserFeedNotifications,
  listenUserContacts,
  listenUserFollower,
  listenUserFollowerPending,
  listenUserFollowing,
  listenUserSettings,
  updateUser,
  updateUserCountry,
  countCountry,
  addUserCountry,
  addFollowing,
  deleteFollowing,
  confirmFollower,
  deleteFollower,
  getInvite,
  fbCreateUser,
  deleteInvite,
  addPrivateFcmToken,
  deletePrivateFcmToken,
  setAppleRefreshToken,
  listenUserTrips,
  listenUserBlocked,
  listenUserBlockedBy,
  listenUserInvites
} from "./firebase";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
export interface User {
  user: any;
  userNotActive: any;
  profile: any;
  userSettings: any;
  userCountries: any;
  userTrips: any;
  userPrivateCountries: any;
  userError: any;
  adminRole: boolean;
  needsAccountCreation: boolean;
}

interface State {
  user: fbUser | null;
  userNotActive: fbUser | null;
  profile: any;
  userSettings: any;
  userCountries: any;
  userTrips: any;
  userPrivateCountries: any;
  userExport: any;
  userExportExports: any;
  userFeed: any;
  fcmToken: any;
  userFeedNotifications: any;
  userContacts: any;
  userContactsCountries: any;
  userBlocked: any;
  userBlockedBy: any;
  userFollower: any;
  userFollowerPending: any;
  userFollowing: any;
  error: null|string;
  adminRole: boolean;
  needsAccountCreation: boolean;
  signUpDisplayName: any;
  appleAuthorizationCode: any;
  appInitialized: boolean;
  cannyLogin: any;
  userInvites: any;
  gotInviteCode: any;
  algoliaKeyNeedsUpdate: boolean;
}

const getCountryName = (code) => {
  return allCountries.find((x) => x["alpha2Code"].toLowerCase() == code.toLowerCase())
}

const mergeCountryPrivate = (userCountries, userPrivateCountries) => {
  let i = 0
  const merge: any = []
  const arr1 = userCountries
  const arr2 = userPrivateCountries
  while(i < arr1.length){ // find Private Data for each userCountrie and merge
    const findInPrivate: any = arr2.find(x => x.id === arr1[i].id)
    if(findInPrivate){
      merge.push({...arr1[i],...findInPrivate})
    } else {
      merge.push(arr1[i])
    }
    //incrementing start value
    i = i+1
  }
  i = 0
  while(i < arr2.length){ // find userCountrie in above merged array and add
    const findInMerged = merge.find(x => x.id === arr2[i].id)
    if(!findInMerged){
      // private Countrie not found in userCountries
      // get countries name from allCountries
      const staticCountry: any = getCountryName(arr2[i].id)
      arr2[i].countryName = staticCountry.name
      merge.push(arr2[i])
    } 
    //incrementing start value
    i = i+1
  } 
  merge.sort(function(a, b){
    //sort merged userCountries ascending
    if (a.countryName < b.countryName) return -1 
    if (a.countryName > b.countryName) return 1
    return 0
  })
  return merge 
}

export const useAuthStore = defineStore("authStore", {
  // convert to a function
  state: (): State => ({
    user: null,
    userNotActive: null,
    profile: null,
    userSettings: null,
    userCountries: null,
    userTrips: null,
    userPrivateCountries: null,
    userExport: null,
    userExportExports: null,
    userFeed: null,
    fcmToken: null,
    userFeedNotifications: null,
    userContacts: null,
    userContactsCountries: null,
    userBlocked: null,
    userBlockedBy: null,
    userFollower: null,
    userFollowerPending: null,
    userFollowing: null,
    error: null,
    adminRole: false,
    needsAccountCreation: false,
    signUpDisplayName: null,
    appleAuthorizationCode :false,
    appInitialized: false,
    cannyLogin: false,
    userInvites: null,
    gotInviteCode: false,
    algoliaKeyNeedsUpdate: false
  }),
  getters: {
    isLoggedIn: (state) => state.user !== null && state.user.emailVerified,
    userError: (state) => state.error,
  },
  actions: {
    initializeAuthListener() {
        fbAuthStateListener(async (user: any) => {
          this.appInitialized = true
          if(user && (!user.email || user.email == null)) {
            console.log("user with no email gets deleted")
            await user.delete()
            user = false
            this.error = "Error: Unexpected ID used to login. Please try to reset your 3rd party login provider (mostly Apple-ID)"
          }
          if(user && user != null && this.signUpDisplayName && this.signUpDisplayName != null && this.signUpDisplayName != "") {
            updateProfile(user, {
              displayName: this.signUpDisplayName
            })
          }
          if(this.appleAuthorizationCode){
            console.log("there is a apple auth code")
            fetch('https://us-central1-wbb-app.cloudfunctions.net/getRefreshToken?code=' + this.appleAuthorizationCode)
            .then(response => response.text())
            .then(async (data) => {
                await setAppleRefreshToken(user.uid, data)
            })
          }
          this.user = user ? user : this.user ? this.user : null //doesn't helps the weird ios login after logout bug
          this.adminRole = false
          if(user && user.emailVerified) {
            user.getIdTokenResult().then((idTokenResult) => {
              if (idTokenResult.claims.admin) {
                console.log('admin approved')
                this.adminRole = true
              }
            })
            this.initListeners(user)
          } else {
            console.log("user not verified")
          }
        });
    },

    async initListeners(user) {
        if(!user) user = this.user
        this.userSettings = null
        this.userCountries = null
        this.userPrivateCountries = null
        this.userTrips = null
        this.userFeed = null
        this.userFeedNotifications = null
        this.userContacts = null
        this.userContactsCountries = null
        this.userBlocked = null
        this.userBlockedBy = null
        this.userFollower = null
        this.userFollowerPending = null
        this.userFollowing = null
        this.userExport = null
        this.userExportExports = null
        this.userInvites = null
        this.gotInviteCode = false
        this.algoliaKeyNeedsUpdate = true
        listenUser(user.uid, userSnap => {
          this.profile = userSnap;
          if (this.profile.username) this.needsAccountCreation = false
          else this.needsAccountCreation = true
          listenUserInvites(this.profile.username, invitesSnap => {
            this.userInvites = invitesSnap
          })
        })
        listenUserSettings(user.uid, settingsSnap => {
            this.userSettings = settingsSnap
        })
        listenUserCountries(user.uid, countriesSnap => {
            const newCountries = countriesSnap
            this.userCountries = newCountries.sort(function (a, b) {
                //sort merged userCountries ascending
                if (a.countryName < b.countryName) return -1
                if (a.countryName > b.countryName) return 1
                return 0
            })
            if (this.userPrivateCountries && this.userPrivateCountries != null) this.userCountries = mergeCountryPrivate(countriesSnap, this.userPrivateCountries)
            this.updateUserPresence()
        })
        listenUserPrivateCountries(user.uid, countriesSnap => {
            this.userPrivateCountries = countriesSnap
            if (this.userCountries && this.userCountries != null) this.userCountries = mergeCountryPrivate(this.userCountries, countriesSnap)
            else this.userCountries = this.userPrivateCountries.sort(function (a, b) {
                //sort merged userCountries ascending
                if (a.countryName < b.countryName) return -1
                if (a.countryName > b.countryName) return 1
                return 0
            })
        })
        listenUserTrips(user.uid, feedSnap => {
          this.userTrips = feedSnap
        })
        listenUserFeed(user.uid, feedSnap => {
          this.userFeed = feedSnap
        })
        listenUserFeedNotifications(user.uid, notificationsSnap => {
          this.userFeedNotifications = notificationsSnap
        })
        listenUserContacts(user.uid, contactsSnap => {
          this.userContacts = contactsSnap
          this.initContactCountries()
        })
        listenUserBlocked(user.uid, blockedSnap => {
          this.algoliaKeyNeedsUpdate = true
          this.userBlocked = blockedSnap
        })
        listenUserBlockedBy(user.uid, blockedBySnap => {
          this.algoliaKeyNeedsUpdate = true
          this.userBlockedBy = blockedBySnap
        })
        listenUserFollower(user.uid, followerSnap => {
          this.userFollower = followerSnap
        })
        listenUserFollowerPending(user.uid, followerPendingSnap => {
          this.userFollowerPending = followerPendingSnap
        })
        listenUserFollowing(user.uid, followingSnap => {
          this.algoliaKeyNeedsUpdate = true
          this.userFollowing = followingSnap
        })
        listenUserExport(user.uid, exportSnap => {
            this.userExport = exportSnap
        })
        listenUserExportExports(user.uid, exportsSnap => {
            this.userExportExports = exportsSnap
        })
    },

    async updateUserPresence(online=true) {
      //console.log("updatePresence countries length:"+this.userCountries.length)
      if(this.user){
        const userId = this.profile.id
        if(this.userCountries && this.userCountries.length>0){
          const statsCountryMets: any = []
          const statsCountryHomes: any = []
          const statsPlannedCountries: any = []
          const statsVisitedCountries: any = []
          const statsPhotoCountries: any = []
          const statsCountryPhotos: any = []
          const statsExps: any = []
          for (const country of this.userCountries) {
            if(country.planned) statsPlannedCountries.push(country.id.toLowerCase())
            if(country.visited) statsVisitedCountries.push(country.id.toLowerCase())
            if(country.metContacts > 0) statsCountryMets.push(country.id.toLowerCase())
            if(country.homeContacts > 0) statsCountryHomes.push(country.id.toLowerCase())
            if(country.photoUrl && country.photoUrl!="") statsPhotoCountries.push(country.id.toLowerCase())
            if(country.photoUrl && country.photoUrl!="") statsCountryPhotos.push(country.photoUrl)
          }
          if(this.profile.exps && this.profile.exps.length>0){
            for (const exp of this.profile.exps) {
              statsExps.push(exp.name)
            }
          }
          return updateUser(userId, {
            lastPresence: new Date(),
            status: online ? "online" : "offline",
            statsCountryMets: statsCountryMets,
            statsCountryHomes: statsCountryHomes,
            statsPlannedCountries: statsPlannedCountries,
            statsVisitedCountries: statsVisitedCountries,
            statsPhotoCountries: statsPhotoCountries,
            statsCountryPhotos: statsCountryPhotos,
            statsExps: statsExps
          })
            .catch( error => {
              console.log(error)
              console.log("lastPresence error")
            })
        } else {
          console.log("lets update user ")
          return updateUser(userId, {
            lastPresence: new Date(),
            status: online ? "online" : "offline",
          })
            .catch( error => {
              console.log(error)
              console.log("lastPresence error")
            })
        }
      }
    },

    async savePrivateFcmToken(token) {
      console.log("savePrivateFcmToken")
      console.log(token)
      console.log("userId", this.profile.id)
      addPrivateFcmToken(this.profile.id, token)
        .then(() => {
          console.log("success savePrivateFcmToken")
        })
        .catch(e => {
          console.log(e)
          console.log("error savePrivateFcmToken (or already exists)")
        })
    },

    async removePrivateFcmToken() {
      console.log("remove fcm token:", this.fcmToken)
      if(!this.fcmToken || this.fcmToken===null) return true
      return await deletePrivateFcmToken(this.profile.id, this.fcmToken)
    },

    async follow(user) {
      return addFollowing(this.profile.id, user)
    },

    
    async requestFollow(username, userId) {
      return addFollowing(this.profile.id, {id: userId, username: username})
    },

    async cancelRequestFollow(userId) {
      return deleteFollowing(this.profile.id, {id: userId})
    },

    async confirmFollow(user) {
      return confirmFollower(this.profile.id, user)
    },

    async unfollow(user) {
      return deleteFollowing(this.profile.id, user)
    },

    async declineFollow(user) {
      return deleteFollower(this.profile.id, user)
    },

    getCountry(place) {
      if(place.id){
        if(place.id.includes("country.")) return place.properties.short_code
        else {
          return place.context.find(x => x.id.includes("country.")).short_code
        }
      } else {
        return place.countrycode
      }
    },

    async addContact(where, contact) {
      const country = (contact[where].countrycode ? contact[where].countrycode : this.getCountry(contact[where])).toUpperCase()
      const contactsWhere = (where == "met" ? "contactsMet" : "contactsHome")
        if(!this.userContactsCountries.find(x => x.country == country)) {
          this.userContactsCountries.push({ country: country, contactsMet: [], contactsHome: [], following: [], contactsMetNoPhoto: 0, contactsHomeNoPhoto: 0,  followingNoPhoto: 0})
        }
        if(contact.photoUrl){
          this.userContactsCountries.find(x => x.country == country)[contactsWhere].push(contact.id)
        } else {
          this.userContactsCountries.find(x => x.country == country)[contactsWhere+"NoPhoto"]++
        }
    },

    async initContactCountries() {
      this.userContactsCountries = []
      if(this.userContacts){
        this.userContacts.forEach(contact => {
          if(contact.met) {
            this.addContact("met", contact)
          }
          if(contact.home) {
            this.addContact("home", contact)
          }
          if(contact.homes) {
            contact.homes.forEach(home => {
              this.addContact("home", {...contact, home})
            })
          }
        })
      }
      // if(this.userFollowing){
      //   this.userFollowing.forEach(async (following) => {
      //     this.addFollowing(following)
      //     const profileData = await getAppProfileData(following.username)
      //     if(profileData.place && Object.keys(profileData.place).length > 0) addFollowing(profileData)
      //   })
      // }
    },

    async countCountryContacts(content) {
      // update counter homeCountacts / metContacts on Country Docs
      const userCountry = this.userCountries.find(x => x.country === content.country.toUpperCase())
      if(userCountry) {
        // console.log('country exists - '+content.where)
        //updateCountry with content.direction
        if(content.where == "met" && content.direction > 0 && !userCountry.visited){
          const editCountry = JSON.parse(JSON.stringify(userCountry))
          editCountry.visited = true
          updateUserCountry(this.profile.id, this.profile.username, editCountry)
        }
        countCountry(this.profile.id, content).then(() => {
          return new Promise<void>((resolve) => {
            resolve()
          })
        })
      } else if(content.direction > 0) {
        //in case the country wasn't in userCountries we add it
        // console.log('country add - '+content.where)
        const country = {
          country: content.country,
          countryName: content.countryname,
          createdOn: new Date(),
          updatedOn: new Date(),
          userId: this.profile.id,
          [content.where+'Contacts']: 1,
          visited: content.where == 'met' ? true : false 
        }
        addUserCountry(this.profile.id, this.profile.username, country).then(() => {
          return new Promise<void>((resolve) => {
            resolve()
          })
        })
      } else {
        return new Promise<void>((resolve) => {
          resolve()
        })
      }
    },

    async resendVerification() {
      await fbResendVerification(this.userNotActive)
       .then(() => {
          this.userNotActive = null
          this.error = null;
          return true      
        })
    },

    async logInUser(email: string, password: string) {
      try {
        const response = await fbSignIn(email, password);
        this.user = response.user && response.user.emailVerified ? response.user : null;
        if(this.user != null){
          this.error = null;
          console.log("login success")
          return true;
        } else {
          this.error = "Account is not activated!";
          this.userNotActive = response.user
          console.log("login failed (not verified)")
          return false;
        }
      } catch (e: any) {
        this.user = null;
        this.error = e;
        console.log("login failed")
        return false;
      }
    },

    async logoutUser() {
      try {
        await FirebaseAuthentication.signOut()
        await fbSignOut();
        this.appleAuthorizationCode = false;
        this.signUpDisplayName = null;
        this.user = null;
        this.profile = null;
        // this.userCountries = null
        // this.userSettings = null
        // this.userTrips = null
        // this.userPrivateCountries = null
        // this.userExport = null
        // this.userExportExports = null
        // this.userFeed = null
        // this.fcmToken = null
        // this.userFeedNotifications = null
        // this.userContacts = null
        // this.userBlocked = null
        // this.userBlockedBy = null
        // this.userFollower = null
        // this.userFollowerPending = null
        // this.userFollowing = null
        // this.adminRole = false
        // this.needsAccountCreation = false
        // this.appInitialized = false
        // this.cannyLogin = false
        // this.userInvites = null
        // this.gotInviteCode = false
        //this.error = null;
        return true;
      } catch (e: any) {
        console.log("Logouterror",e)
        this.error = e;
        return false;
      }
    },

    async completeUser(data: any) {
      return new Promise<void>((resolve, reject) => {
        this.error = null;
        if(!data.invite || data.invite == "false") data.invite = "noinvite"
        if(data.invite){
          getInvite(data.invite).then((inv) => {
            if(inv.createdOn || !inv){
              if(/^[a-zA-Z0-9_]*([a-zA-Z0-9_]+)*$/.test(data.username) && !data.username.includes("__") && data.username.length > 2 && data.username.length < 31){
                if(data.first != "" && data.last != "") {
                  fbCreateUser(this.user, data, inv)
                    .then(() => {
                      console.log("fbCreateUser then")
                      this.user = null
                      resolve()
                      if(inv.once) {
                        deleteInvite(data.invite)
                          .then( () => {
                            resolve()
                          })
                          .catch( e => {
                            this.error = e
                            reject()
                          }) 
                      }
                    })
                    .catch(e => {
                      console.log("fb create user catch", e)
                      //this.logoutUser()
                      this.error = e
                      reject()
                    })
                } else {
                  this.error ="Please tell us your name"
                reject()
                }
              } else {
                this.error ="Your username is not allowed. It can only contain letters, numbers, and _. It can't contain symbols or punctuation marks."
                reject()
              }
            } else {
              this.error = "Invalid invite code"
              reject()
            }
          })
        } else {
          this.error = "Missing invite code"
          reject()
        }
      })
    },

    async createUser(data: any) {
      return new Promise<void>((resolve, reject) => {
        this.error = null;
        if(!data.invite || data.invite == "false") data.invite = "noinvite"
        if(data.invite){
          getInvite(data.invite).then((inv) => {
            if(inv.createdOn){
              if(/^[a-zA-Z0-9_]*([a-zA-Z0-9_]+)*$/.test(data.username) && !data.username.includes("__") && data.username.length > 2 && data.username.length < 31){
                if(data.first != "" && data.last != "") {
                  fbCreateAccount(data)
                    .then(() => {
                      fbCreateUser(this.user, data, inv)
                        .then(() => {
                          this.user = null
                          // this.logoutUser()
                          // resolve()
                          //workaround to not delete invites for now:
                          if(!inv || inv.once && !inv.once){ //invites won't delete
                            deleteInvite(data.invite)
                              .then( () => {
                                this.logoutUser()
                                resolve()
                              })
                              .catch( e => {
                                this.error = e
                                this.logoutUser()
                                reject()
                              })
                          } else {
                            this.logoutUser()
                            resolve()
                          }
                        })
                        .catch(e => {
                          console.log("fb create user catch", e)
                          this.logoutUser()
                          this.error = e
                          reject()
                        })
                    })
                    .catch(e => {
                      console.log("fbCreateAccount catch", e)
                      this.error = e
                      reject()
                    })
                } else {
                  this.error ="Please tell us your name"
                reject()
                }
              } else {
                this.error ="Your username is not allowed. It can only contain letters, numbers, and _. It can't contain symbols or punctuation marks."
                reject()
              }
            } else {
              this.error = "Invalid invite code"
              reject()
            }
          })
        } else {
          this.error = "Missing invite code"
          reject()
        }
      })
    },
  },
});