
import { defineComponent, ref } from "vue";
import { useAppStore } from "@/store";
import { storeToRefs } from "pinia";
import { useCookies } from "vue3-cookies";
import { IonButton, IonContent, IonModal, IonTitle,  IonToolbar } from "@ionic/vue";

export default defineComponent({
  name: "ConsentModal", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent
  },
  setup() {
    const { cookies } = useCookies();
    const { cookieConsent, cookieModal } = storeToRefs(useAppStore())

    const view = ref('global')
    const options = ref()
    const modalConn = ref()

    const userId = ref()
    const usernameProfile:any = ref(false)
    const publicProfile = ref(false)
    const followStatus = ref('none') //none, pending, confirmed
    const followLoading = ref(false)

    const contact = ref(false)
    const userToConnect = ref();

    const closeModal = () => {
      modalConn.value.$el.setCurrentBreakpoint(0)
    }

    const beforePresent = () => {
      if(cookies.isKey('seenCookiePopup')){
        cookieModal.value = false
      }
    }

    const beforeDismiss = () => {
      console.log("beforeDismiss")
      dismissModal()
    }

    const acceptCookie = () => {
      cookieConsent.value = true
      cookies.set("analyticsConsent", "true", "30d");
      cookieModal.value = false
    }

    const dismissCookie = () => {
      cookies.remove("analyticsConsent")
      cookieConsent.value = false
      dismissModal()
    }

    const dismissModal = () => {
      cookies.set("seenCookiePopup", "true", "30d");
      cookieModal.value = false
    }

    return {
      beforePresent,
      beforeDismiss,
      options,
      closeModal,
      acceptCookie,
      dismissCookie,
      dismissModal,
      cookieModal
    }
  }
});
