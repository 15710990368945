
import { useAppStore, useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { defineComponent, ref } from "vue";
import { IonButton, IonFabButton, IonIcon } from '@ionic/vue';
import { pencilOutline, sparklesOutline, addOutline, pinOutline, homeOutline, pricetagsOutline, calendarClearOutline, documentTextOutline, lockClosedOutline, navigateOutline, calendarOutline } from 'ionicons/icons';
import MarkdownWrap from "@/components/MarkdownWrap.vue"

export default defineComponent({
  name: "OwnCountryModal",
  components: {
    MarkdownWrap,
    IonIcon,
    IonButton,
    IonFabButton
  },
  setup() {
    const { modalCountry } = storeToRefs(useAppStore())
    const { profile, userTrips } = storeToRefs(useAuthStore())
    const { openEditModalCountry, openEditModalContact } = useAppStore()

    if(!modalCountry.value.ownCountry || modalCountry.value.ownCountry == undefined) openEditModalCountry(modalCountry.value.code)

    const countryTrips:any = ref([])

    userTrips.value.forEach((trip) => {
      if(trip.countries && trip.countries.includes(modalCountry.value.code?.toLowerCase())) countryTrips.value.push(trip)
      countryTrips.value.sort(function(a,b){
        return b.start - a.start;
      });
    })

    return {
        modalCountry,
        openEditModalCountry,
        pencilOutline,
        sparklesOutline,
        pricetagsOutline,
        calendarClearOutline,
        calendarOutline,
        pinOutline,
        addOutline,
        documentTextOutline,
        lockClosedOutline,
        navigateOutline,
        homeOutline,
        profile,
        userTrips,
        countryTrips,
        openEditModalContact
    }
  }
});
