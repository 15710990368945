
import { defineComponent, ref } from "vue";
import { useAppStore } from "@/store";
import { storeToRefs } from "pinia";
import { useCookies } from "vue3-cookies";
import { isPlatform, IonButton, IonContent, IonModal, IonTitle,  IonToolbar } from "@ionic/vue";

export default defineComponent({
  name: "UpdateModal", 
  components: {
    IonModal,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent
  },
  setup() {
    const { cookies } = useCookies();
    const { updateModal } = storeToRefs(useAppStore())

    const beforePresent = () => {
      //console.log("before present")
    }

    const beforeDismiss = () => {
      //console.log("before Dismiss")
      cookies.set("seenUpdate", "true", "2d");
    }
    return {
      updateModal,
      beforeDismiss,
      beforePresent,
      isPlatform
    }
  }
});
