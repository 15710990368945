import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start flex-wrap mb-4 bg-zinc-100 dark:bg-zinc-800 p-4 rounded-xl mt-5" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "text-2xl mr-2" }
const _hoisted_4 = { class: "bg-zinc-200 dark:bg-zinc-700 p-2 my-1 mr-2 lg:my-2 lg:mr-4 rounded-lg text-center text-sm lg:text-base" }
const _hoisted_5 = { class: "bg-zinc-200 dark:bg-zinc-700 p-2 my-1 mr-2 lg:my-2 lg:mr-4 rounded-lg text-center text-sm lg:text-base" }
const _hoisted_6 = { class: "bg-zinc-200 dark:bg-zinc-700 p-2 my-1 mr-2 lg:my-2 lg:mr-4 rounded-lg text-center text-sm lg:text-base" }
const _hoisted_7 = { class: "bg-zinc-200 dark:bg-zinc-700 p-2 my-1 mr-2 lg:my-2 lg:mr-4 rounded-lg text-center text-sm lg:text-base" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "bg-zinc-200 dark:bg-zinc-700 p-2 my-1 mr-2 lg:my-2 lg:mr-4 rounded-lg text-center text-sm lg:text-base" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex items-center space-x-2 pb-4" }
const _hoisted_15 = { class: "text-sm" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["src"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  key: 2,
  class: "w-full pt-1 flex flex-wrap items-center"
}
const _hoisted_20 = {
  key: 0,
  class: "bg-zinc-200 dark:bg-zinc-700 rounded-lg p-1 px-2 text-sm h-7 mr-2 mt-2"
}
const _hoisted_21 = {
  key: 0,
  class: "bg-zinc-200 dark:bg-zinc-700 rounded-lg p-1 px-2 text-sm h-7 mr-2 mt-2"
}
const _hoisted_22 = {
  key: 1,
  class: "text-center block w-full"
}
const _hoisted_23 = {
  key: 2,
  class: "text-center block w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.modalCountry.staticCountry.nativeName), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalCountry.staticCountry.translations, (translation, id) => {
          return (_openBlock(), _createElementBlock("span", {
            key: id,
            class: "inline-block mr-2 text-sm lg:text-md text-zinc-600 dark:text-zinc-400"
          }, _toDisplayString(translation), 1))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, " 🌐 " + _toDisplayString(_ctx.modalCountry.staticCountry.subregion), 1),
      _createElementVNode("div", _hoisted_5, " 📍 " + _toDisplayString(_ctx.modalCountry.staticCountry.capital), 1),
      _createElementVNode("div", _hoisted_6, " 🧑‍🤝‍🧑 " + _toDisplayString(_ctx.modalCountry.staticCountry.population.toLocaleString()), 1),
      _createElementVNode("div", _hoisted_7, [
        _createTextVNode(" 💬 "),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalCountry.staticCountry.languages, (language, key) => {
          return (_openBlock(), _createElementBlock("span", {
            key: language.code
          }, [
            _createTextVNode(_toDisplayString(language.name), 1),
            (key+1 != _ctx.modalCountry.staticCountry.languages.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, ", "))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createTextVNode(" 💸 "),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalCountry.staticCountry.currencies, (currency, key) => {
          return (_openBlock(), _createElementBlock("span", {
            key: currency.code
          }, [
            _createTextVNode(_toDisplayString(currency.code), 1),
            (key+1 != _ctx.modalCountry.staticCountry.currencies.length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", "))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    (_ctx.modalCountry.globalCountry)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_TransitionGroup, { name: "list" }, {
            default: _withCtx(() => [
              (!_ctx.countryUsersLoading && _ctx.modalCountry.globalCountry.visitedCount && _ctx.countryUsers && _ctx.countryUsers.length>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryUsers, (user) => {
                      return (_openBlock(), _createElementBlock("div", {
                        onClick: ($event: any) => (_ctx.changeUser(user.username)),
                        key: user.id,
                        class: "block bg-zinc-100 dark:bg-zinc-800 rounded-xl p-4 mb-4"
                      }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_UserAvatar, {
                            username: user.username,
                            photoUrl: user.photoUrl,
                            class: "h-8 w-8"
                          }, null, 8, ["username", "photoUrl"]),
                          _createElementVNode("span", _hoisted_15, _toDisplayString(user.username), 1)
                        ]),
                        (user.countryData)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              (user.countryData.photoUrl)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: user.countryData.photoUrl,
                                    class: "block w-full rounded-lg object-cover mb-4",
                                    style: {"aspect-ratio":"16/9"}
                                  }, null, 8, _hoisted_17))
                                : _createCommentVNode("", true),
                              (user.countryData.shortNote)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(user.countryData.shortNote), 1))
                                : _createCommentVNode("", true),
                              (user.countryData.exps && user.countryData.exps.length>0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    _createVNode(_component_IonIcon, {
                                      icon: _ctx.pricetagsOutline,
                                      class: "text-lg inline-block mr-3 shrink-0"
                                    }, null, 8, ["icon"]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.countryData.exps, (exp, index) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: exp }, [
                                        (index<5)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(exp), 1))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128)),
                                    (user.countryData.exps.length>5)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(user.countryData.exps.length-5) + " +", 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_13))
                    }), 128)),
                    (_ctx.modalCountry.globalCountry.visitedCount-_ctx.countryUsers.length>0)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: '/connections/explore/#cntr'+_ctx.modalCountry.code,
                          class: "text-center bg-zinc-100 dark:bg-zinc-800 rounded-xl w-full block p-4 text-mwhighlight underline"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("explore " + _toDisplayString(_ctx.modalCountry.globalCountry.visitedCount-_ctx.countryUsers.length) + " more", 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true)
                  ]))
                : (!_ctx.countryUsersLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, "no user was here"))
                  : _createCommentVNode("", true),
              (_ctx.countryUsersLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createVNode(_component_ion_spinner, { name: "crescent" })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}