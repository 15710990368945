
import { defineComponent, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore, useAuthStore, allCountries } from "@/store";
import { addContact, deleteContact, updateContact } from "@/store/firebase";
import { IonButton, IonHeader, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea, IonToolbar, toastController, IonIcon, IonFooter, IonTitle } from "@ionic/vue";
import ProfilePhoto from "@/components/settings/ProfilePhoto.vue"
import LocationInput from "@/components/LocationInput.vue"
import UserInput from "@/components/UserInput.vue"
import ContactInput from "@/components/settings/ContactInput.vue"
import UserAvatar from "@/components/UserAvatar.vue"
import { useRoute, useRouter } from "vue-router";
import { addCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: "EditContactModal",
  components: {
    IonGrid,
    IonHeader,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonModal,
    IonToolbar,
    IonContent,
    IonTextarea,
    IonInput,
    IonButton,
    LocationInput,
    UserInput,
    ProfilePhoto,
    ContactInput,
    UserAvatar,
    IonIcon,
    IonFooter,
    IonTitle
  },
  setup() {
    const { profile } = storeToRefs(useAuthStore())
    const { modalConnection, editModalContact, editModalContactFocus, appLoading } = storeToRefs(useAppStore())
    const { countCountryContacts } = useAuthStore()
    const { getStoredUserId, getStoredUser } = useAppStore()
    const router = useRouter()
    const route = useRoute()

    const modal = ref()
    const editContact = ref()
    const userInputTrigger = ref(false)
    const scrollcontainer = ref()
    const nameError = ref(false)
    const locationInputs = ref([])
    const editDetails= ref(false)

    const beforePresent = () => {
      editDetails.value = false
      editContact.value = { name: '' }
      if(editModalContact.value.id) {
        editContact.value = JSON.parse(JSON.stringify(editModalContact.value))
        if(modalConnection.value.userToConnect){
          editContact.value.appprofile = modalConnection.value.userToConnect
          editDetails.value = true
        }
      }
      if(!editContact.value.homes){
        editContact.value.homes = []
      } 
      if(editContact.value.home){
        editContact.value.homes.push(editContact.value.home)
        editContact.value.home = false
      }
    }

    const didPresent = async () => {
      userInputTrigger.value = false
      if(editModalContactFocus.value != ""){
        if(editModalContactFocus.value == "find") {          
          setTimeout(() => {
            // modal.value.$el.setCurrentBreakpoint(0.9)
            userInputTrigger.value = true
          }, 100)
        }
        else if(editModalContactFocus.value == "existing") {          
          setTimeout(() => {
            // modal.value.$el.setCurrentBreakpoint(0.9)
          }, 100)
        }
      }
      if(!editContact.value.appprofile && modalConnection.value.username != undefined) {
        editContact.value.appprofile = modalConnection.value.username
        if(!editContact.value.name || editContact.value.name==""){
          const appprofileData = await getAppProfileData(modalConnection.value.username)
          editContact.value.name = appprofileData.name + ' ' + appprofileData.lastname
        }
      }
    }

    const getAppProfileData = async (username) => {
      const userId = await getStoredUserId(username)
      const userData = await getStoredUser(userId.uid)
      return userData
    }

    const getCountry = (place) => {
      if(!place || place == undefined) return false
      if(place.id){
        if(place.id.includes("country.")) return place.properties.short_code
        else {
          return place.context.find(x => x.id.includes("country.")).short_code
        }
      } else {
        return place.countrycode 
      }
    }

    const getCountryData = (code) => {
      // if(code == "") return { name: "" } //workaround for countryCounter
      return allCountries.find((x) => x.alpha2Code.toLowerCase() == code.toLowerCase())
    }

    const countryCounter = (where, action) => {
      // update counters on country docs
      return new Promise<void>( (resolve, reject) => {
        let key = where // met / home / homes
        if(action=='add') {
          // new contact so increase counter
          if(key == 'homes'){
            editContact.value.homes.forEach(home => {
              if(home){
                countCountryContacts({
                  country: home.countrycode ? home.countrycode : getCountry(home),
                  countryname: home.country ? home.country : getCountryData(getCountry(home))?.name,
                  where: "home",
                  direction: 1
                }).then( () => {
                  resolve() 
                })
              }
            });
          } else {
            countCountryContacts({
              country: editContact.value[key].countrycode ? editContact.value[key].countrycode : getCountry(editContact.value[key]),
              countryname: editContact.value[key].country ? editContact.value[key].country : getCountryData(getCountry(editContact.value[key]))?.name,
              where: key,
              direction: 1
            }).then( () => {
              resolve() 
            })
          }
        } else if(action=='delete'){
          // deleted contact so decrease counter
          if(key == 'homes'){
            editModalContact.value.homes.forEach(home => {
              if(home){
                countCountryContacts({
                  country: home.countrycode ? home.countrycode : getCountry(home),
                  countryname: home.country ? home.country : getCountryData(getCountry(home))?.name,
                  where: "home",
                  direction: -1
                }).then( () => {
                  resolve() 
                })
              }
            });
          } else {
            if(editModalContact.value[key] && (editModalContact.value[key].countrycode || getCountry(editModalContact.value[key]))){
              countCountryContacts({
                country: editModalContact.value[key].countrycode ? editModalContact.value[key].countrycode : getCountry(editModalContact.value[key]),
                countryname: editModalContact.value[key].country ? editModalContact.value[key].country : getCountryData(getCountry(editModalContact.value[key]))?.name,
                where: key,
                direction: -1
              }).then( () => {
                resolve() 
              })
            }
          }
        } else {
          if(key == 'homes'){
            if(editModalContact.value.homes){
              //decrease old homes
              editModalContact.value.homes.forEach(home => {
                if(home){
                  countCountryContacts({
                    country: home.countrycode ? home.countrycode : getCountry(home),
                    countryname: home.country ? home.country : getCountryData(getCountry(home))?.name,
                    where: "home",
                    direction: -1
                  }).then( () => {
                    resolve()
                  })
                }
              });
            }
            if(editContact.value.homes) {
              //increade new homes
              editContact.value.homes.forEach(home => {
                if(home){
                  countCountryContacts({
                    country: home.countrycode ? home.countrycode : getCountry(home),
                    countryname: home.country ? home.country : getCountryData(getCountry(home))?.name,
                    where: "home",
                    direction: 1
                  }).then( () => {
                    resolve() 
                  })
                }
              });
            }
          }
          //2DO improve logic by checking if country has changed
          // decrease old country
          if(editModalContact.value[key] && editModalContact.value[key].countrycode ? editModalContact.value[key].countrycode : getCountry(editModalContact.value[key])){
            countCountryContacts({
              country: editModalContact.value[key].countrycode ? editModalContact.value[key].countrycode : getCountry(editModalContact.value[key]),
              countryname: editModalContact.value[key].country ? editModalContact.value[key].country : getCountryData(getCountry(editModalContact.value[key]))?.name,
              where: key,
              direction: -1
            }).then(() => {
              if(editContact.value[key] && editContact.value[key].countrycode ? editContact.value[key].countrycode : getCountry(editContact.value[key])){
                // increase new country
                countCountryContacts({
                  country: editContact.value[key].countrycode ? editContact.value[key].countrycode : getCountry(editContact.value[key]),
                  countryname: editContact.value[key].country ? editContact.value[key].country : getCountryData(getCountry(editContact.value[key]))?.name,
                  where: key,
                  direction: 1
                })
              }
            })
          } else if(editContact.value[key] && editContact.value[key].countrycode ? editContact.value[key].countrycode : getCountry(editContact.value[key])){
            // increase new country
            countCountryContacts({
              country: editContact.value[key].countrycode ? editContact.value[key].countrycode : getCountry(editContact.value[key]),
              countryname: editContact.value[key].country ? editContact.value[key].country : getCountryData(getCountry(editContact.value[key]))?.name,
              where: key,
              direction: 1
            })
          }
        }
      })
    }

    const trash = () => {
      deleteContact(profile.value.id, profile.value.username, editContact.value.id).then(async() => {
        countryCounter('met', 'delete')
        countryCounter('home', 'delete')
        countryCounter('homes', 'delete')
        modalConnection.value = false
        editModalContact.value = false
        const toast = await toastController
          .create({
            message: 'Contact has been deleted.',
            duration: 2000,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          toast.present();
      })
    }

    const newContact = () => {
      appLoading.value = true
      nameError.value = false
      //where is the new?
      if(editContact.value.name){
        //editContact.value.country = getCountry(editContact.value.home)
        addContact(profile.value.id, editContact.value, profile.value.username).then(async () => {
          // trigger counting:
          if(editContact.value.met && getCountryData(getCountry(editContact.value.met))?.name) countryCounter('met', 'add').then( () => {
            //console.log('met added')
          })
          if(editContact.value.home && getCountryData(getCountry(editContact.value.home))?.name) countryCounter('home', 'add').then( () => {
            //console.log('home added')
          })
          if(editContact.value.homes) countryCounter('homes', 'add').then( () => {
            //console.log('home added')
          })
          appLoading.value = false
          const toast = await toastController
            .create({
              message: 'Contact has been saved.',
              duration: 2000,
              position: 'top',
              buttons: [
                {
                  side: 'end',
                  text: 'ok',
                  role: 'cancel',
                  handler: () => {
                    console.log('Cancel clicked');
                  }
                }
              ]
            })
            toast.present();
            editModalContact.value = false
        })
      } else {
        nameError.value = true
        appLoading.value = false
      }
    }

    const save = () => {
      appLoading.value = true
      if(editContact.value.homes) {
        editContact.value.homes.forEach((home, index) => {
          if(!home){
            editContact.value.homes.splice(index, 1)
          }
        })
      }
      if(!editContact.value.id) {
        newContact()
      } else {
        countryCounter('met', 'update')
        countryCounter('home', 'update')
        countryCounter('homes', 'update')
        updateContact(profile.value.id, editContact.value.id, editContact.value, profile.value.username).then(async () => {
          if(modalConnection.value.contact.id === editContact.value.id ) {
            modalConnection.value = { contact: editContact.value }
          }
          appLoading.value = false
          const toast = await toastController
          .create({
            message: 'Contact has been saved.',
            duration: 2000,
            position: 'top',
            buttons: [
              {
                side: 'end',
                text: 'ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }
            ]
          })
          toast.present();
          editModalContact.value = false
        })
      }
      // if #cnct remove hashtag and reset the contact page and maybe open the edited contact
      if(modalConnection.value.userToConnect) {
        modalConnection.value.userToConnect = false
        router.push("/connections/contacts")
      }
    }

    const addPlace = () => {
      editContact.value.homes.push(false)
      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        locationInputs.value[locationInputs.value.length-1].open = true
      },100)
    }

    watch(scrollcontainer, async () => {
      if(scrollcontainer.value){
        scrollcontainer.value.$el.ontouchmove = (e) => {
          e.stopPropagation();
        }
      }
    })

    watch(editModalContact, async () => {
      if(editModalContact.value){
        if(modalConnection.value.userToConnect){
          editContact.value.appprofile = modalConnection.value.userToConnect
          editDetails.value = true
        }
        await router.push(router.currentRoute.value.path+"#mdEditContact"+editModalContact.value.id)
      } else {
        if(route.hash.startsWith("#mdEditContact")) {
          history.back()
        }
      }
    })   

    addEventListener('popstate', (event) => { 
      editModalContact.value = false
    });

    return {
        modalConnection,
        editModalContact,
        beforePresent,
        didPresent,
        editContact,
        save,
        trash,
        modal,
        editModalContactFocus,
        userInputTrigger,
        scrollcontainer,
        nameError,
        addCircleOutline,
        addPlace,
        locationInputs,
        editDetails
    }
  }
});
