
import { defineComponent, onUpdated, ref, watch } from "vue";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonToolbar } from "@ionic/vue";
import { chevronBackOutline, searchOutline } from 'ionicons/icons';

export default defineComponent({
  name: "CountryYears", 
  components: {
    IonHeader,
    IonModal,
    IonContent,
    IonList,
    IonItem,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon
  },
  props: {
    selected: Array
  },
  emits: ["change"],
  setup(props, context) {
    const years = ref<any[]>([])
    const initalSelected = props.selected ? JSON.parse(JSON.stringify(props.selected)) : []
    const selectedOptions = ref(initalSelected)
    const open = ref(false)
    const input = ref("")
    const found = ref<object[]>([])
    const toolbar = ref()
    const content = ref()
    const inputelhome = ref()

    let year = new Date().getFullYear()
    years.value = []
    for (let i = year; i > 1919; i--) {
        years.value.push(''+i+'')
    }

    const onFilter = () => {
      if(input.value=="") found.value = years.value
      else found.value = years.value.filter((cnty: any) => cnty.includes(input.value.toLowerCase()))
      selectedOptions.value.forEach((option) => {
        found.value = found.value.filter((cnty:any) => cnty !== option)
      })
    }

    const adjustOffset = () => {
      if(content.value) {
        content.value.$el.setAttribute("style", "--offset-bottom:-"+toolbar.value.$el.offsetHeight+"px")
      } else {
        setTimeout(adjustOffset, 500)
      }
    }

    const emitSelected = () => {
      context.emit("change", selectedOptions.value)
    }

    const add = (year: string) => {
      selectedOptions.value.push(year)
      if(input.value == "") onFilter()
      else input.value = ""
      emitSelected()
      focusInput()
    }

    const remove = (year: string) => {
      selectedOptions.value = selectedOptions.value.filter((cntry: any) => cntry !== year)
      if(input.value == "") onFilter()
      else input.value = ""
      emitSelected()
      focusInput()
    }

    const focusInput = () => {
      if(open.value) inputelhome.value.$el.setFocus()
    }

    watch(open, async () => {
      setTimeout(() => {
        focusInput()
      }, 1500)
    })

    onFilter()

    onUpdated(() => {
      adjustOffset()
    })

    return {
      years,
      selectedOptions,
      open,
      input,
      onFilter,
      found,
      add,
      remove,
      toolbar,
      content,
      inputelhome,
      chevronBackOutline,
      searchOutline
    };
  },
});
