
import { IonButton, IonIcon, IonModal, IonSpinner } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { Cropper } from 'vue-advanced-cropper';
import { closeCircleOutline } from 'ionicons/icons';
import picaImport from 'pica';

export default defineComponent({
  name: "CountryPhoto", 
  components: {
    IonButton,
    IonModal,
    IonSpinner,
    IonIcon,
    Cropper
  },
  emits: ["accept"],
  setup(props, context) {
    const pica = picaImport()
    const fileInput = ref()
    const modalPhotoUrl = ref(false)

    const cropper = ref()
    const cropperImage = ref()

    const triggerFileInput = () => {
			fileInput.value.click()
		}

    const onChooseFile = () => {
      console.log(fileInput.value.files[0])
      modalPhotoUrl.value = true
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.value.files[0])
      reader.onload = async () => {
        // console.log(fileInput.value.files[0])
        cropperImage.value = reader.result;
      }
    }

    const acceptPhotoUrl = async () => {
			const { coordinates, image, visibleArea, canvas } = cropper.value.getResult();
        let baseCanvas = canvas
        // console.log("canvas width check", canvas.width)
        if(canvas.width > 2000) {
          // console.log("resize bigger then 2000")
          const betweenCanvas = document.createElement('canvas');
          betweenCanvas.width = canvas.width/2
          betweenCanvas.height = canvas.height/2
          if(canvas.width > 5000) {
            // console.log("resize bigger then 5000")
            betweenCanvas.width = canvas.width/4
            betweenCanvas.height = canvas.height/4
          }
          await pica.resize(canvas, betweenCanvas)
            .then(result => {
              // console.log("between resize done")
              baseCanvas = result
            });
        }
        const to = document.createElement('canvas');
        const ratio = 4/3
        to.width = 800
        to.height = to.width/ratio
        // console.log("to canvas", to)
        pica.resize(baseCanvas, to)
          .then(result => pica.toBlob(result, 'image/jpeg', 0.90))
          .then(blob => {
            // console.log('resized to canvas & created blob!')
            var reader = new FileReader();
            reader.onload = function(e) {
              context.emit("accept", e.target?.result)
            }
            reader.readAsDataURL(blob);
          });
        modalPhotoUrl.value = false
		}

    const removePhoto = () => {
      context.emit("accept", false)
    }

    return {
      cropper,
      cropperImage,
      fileInput,
      modalPhotoUrl,
      triggerFileInput,
      acceptPhotoUrl,
      onChooseFile,
      removePhoto,
      closeCircleOutline
    };
  },
});
